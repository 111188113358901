<template>
  <div class="accordion">
    <div v-for="l in lessons" :key="l.id" class="card">
      <div :class="$style.cardHeader" class="card-header">
        <BButton variant="link" :class="$style.lessonButton" @click="activeLesson = l.id">
          {{ $t('_lesson_' + l.id) }}
        </BButton>
        <em v-if="l.state === 0">(draft)</em>
      </div>
      <BCollapse :id="'lesson-' + l.id" :visible="activeLesson === l.id">
        <div :class="$style.cardBody" class="card-body">
          <ul class="list-group">
            <li
              v-if="credits[l.id]"
              :class="[$style.credits, $style.listGroupItem]"
              class="list-group-item"
              v-html="credits[l.id]"
            />
            <li
              v-for="a in l.activities"
              :key="a.id"
              :class="$style.listGroupItem"
              class="list-group-item"
            >
              <RouterLink
                :to="{
                  name: 'lesson_' + a.type,
                  params: { lessonId: l.id, activityId: a.id },
                }"
              >
                {{ $t('_activity_' + a.id) }}
              </RouterLink>
            </li>
          </ul>
        </div>
      </BCollapse>
    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue';

import { OPEN_LESSON_ID } from '../store/mutationTypes';

export default {
  components: { BCollapse },
  props: {
    lessons: {
      type: Array,
      required: true,
    },
    categoryId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    credits() {
      const data = {};
      for (const lesson of this.lessons) {
        const id = `_lesson_credits_${lesson.id}`;
        if (this.$te(id)) {
          data[lesson.id] = this.$t(id);
        }
      }
      return data;
    },
    activeLesson: {
      get() {
        const lessonId = this.$store.getters.openLessonId(this.categoryId);
        if (lessonId === null) {
          return this.lessons[0].id;
        }
        return lessonId;
      },
      set(value) {
        if (this.$store.getters.openLessonId(this.categoryId) === value) {
          // Don't comit change if nothing changed! (micro-optimization)
          return;
        }
        this.$store.commit(OPEN_LESSON_ID, {
          categoryId: this.categoryId,
          lessonId: value,
        });
      },
    },
  },
};
</script>

<style module>
.cardHeader {
  padding: 0.15rem 0.25rem;
  border-bottom: none;
}
.cardBody {
  padding: 0;
}
.listGroupItem {
  padding-left: 2rem;
  border: none;
}
.credits {
  padding-left: 1rem;
  padding-bottom: 0;
  font-size: 0.9em;
  color: #919191;
}
.lessonButton:focus {
  text-decoration: none;
  box-shadow: none;
}
</style>
