import { storageName } from '../lib/mobile/fileSystem';
import { getFile } from '../lib/mobile/fileSystem';

export function assetPath(name) {
  if (name.indexOf('https') >= 0) {
    return name;
  }
  if (PRODUCTION) {
    return '/assets/' + name;
  }
  if (IS_MOBILE) {
    return `cdvfile://localhost/${storageName}/${name}`;
  }
  return `http://${window.location.hostname}:3001/assets/${name}`;
}

export async function audioPath(name) {
  if (IS_MOBILE) {
    // Howler can't load audio from the "cdvfile://" path but throws some kind of CORS error
    // so we work around this by reading the file in as a base64 string.
    const fileEntry = await getFile(name, false);

    return new Promise((resolve, reject) => {
      fileEntry.file(
        (file) => {
          const reader = new FileReader();

          reader.onloadend = function () {
            resolve(this.result);
          };

          reader.onerror = function () {
            reject(this.error);
          };

          reader.readAsDataURL(file);
        },
        (error) => reject(error)
      );
    });
  } else {
    return assetPath(name);
  }
}

export const videoScaleFactor = 0.85;
