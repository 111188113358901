<template>
  <ClientLayout>
    <template #title>
      {{ $t('_lesson_' + lessonId) }}/{{ $t('_activity_' + activityId) }}
    </template>

    <template v-if="!activityLoading" #toolbar>
      <div>
        <PageNavigation
          v-if="!showResults"
          v-model="pageId"
          class="float-left"
          :qty-pages="qtyPages"
          :allow-forward="allowForward"
        />
        <ProgressStats
          class="float-right"
          :class="[$style.progressStats]"
          :correct="correct.length"
          :total="completedPage + 1"
          :score="scorePct"
        />
      </div>
    </template>
    <template v-if="!activityLoading">
      <ProgressBar v-if="!showResults" :progress="progress" />

      <ImageGrid
        v-if="!showResults"
        :items="imageContent"
        :play-on-click="false"
        :selectable="false"
      >
        <template #gridFooter>
          <table class="mx-auto">
            <tr v-for="i in content" :key="i.id">
              <td>
                <ContentDisplay
                  :content="i.content"
                  :selectable="true"
                  :success="i.status"
                  class="my-1"
                  :class="[$style.contentDisplay]"
                  @text-click="textClicked(i)"
                />
              </td>
              <td v-if="i.audio">
                <play-icon
                  class="ml-2"
                  :audio="i.audio"
                  :enable-play="!isPlaying"
                  @play-start="isPlaying = true"
                  @play-end="isPlaying = false"
                />
              </td>
            </tr>
          </table>
        </template>
      </ImageGrid>
      <template v-if="showResults">
        <NextActivity :lesson-id="lessonId" :activity-id="activityId" @repeat="repeat" />
        <hr />
        <Results :correct="correct" :incorrect="incorrect" />
      </template>
    </template>
    <Loading v-model="activityLoading" size="lg" center />
  </ClientLayout>
</template>

<script>
import ClientLayout from '../ClientLayout';
import ImageGrid from '../components/ImageGrid';
import ContentDisplay from '../components/ContentDisplay';
import PlayIcon from '../components/PlayIcon';
import ProgressBar from '../components/ProgressBar';
import ProgressStats from '../components/ProgressStats';
import PageNavigation from '../components/PageNavigation';
import Results from '../components/Results';
import Loading from '../components/Loading';

import Lessons from 'mixins/Lessons';
import Activity from 'mixins/Activity';
import IsRtl from '../mixins/IsRtl';

import _shuffle from 'lodash/shuffle';
import _findIndex from 'lodash/findIndex';
import _delay from 'lodash/delay';

import { Correct, Incorrect } from '../helpers/FeedbackSounds';
import NextActivity from '../components/NextActivity';
import { saveProgress } from '../data/Result';

export default {
  components: {
    NextActivity,
    ClientLayout,
    ImageGrid,
    ContentDisplay,
    PlayIcon,
    ProgressBar,
    ProgressStats,
    PageNavigation,
    Results,
    Loading,
  },
  mixins: [Lessons, Activity, IsRtl],
  data() {
    return {
      content: [],
      correctId: 0,
      completedPage: -1,
      correct: [],
      incorrect: [],
      showResults: false,
      isPlaying: false,
      takeId: Date.now(),
    };
  },
  computed: {
    imageContent() {
      if (this.itemsNormalized.length === 0) {
        return [];
      }
      return [this.itemsNormalized[0]];
    },
    allowForward() {
      return this.pageId <= this.completedPage;
    },
    progress() {
      if (this.completedPage < 0) {
        return 0;
      }

      return ((this.completedPage + 1) / this.qtyPages) * 100;
    },
    scorePct() {
      if (this.correct.length === 0) {
        return 0;
      }

      return Math.round((this.correct.length / (this.completedPage + 1)) * 100);
    },
  },
  watch: {
    pageId() {
      this.refreshContent();
    },
  },
  mounted() {
    this.$on('activityLoaded', () => {
      this.refreshContent();
    });
  },
  methods: {
    repeat() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.refreshContent();
    },
    refreshContent() {
      // The "correct" answer is always the first one in the list.
      this.correctId = this.itemsNormalized[0].id;
      this.itemsNormalized.forEach((v) => {
        // must be defined on the content as Vue can't detect property additions at runtime.
        // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
        this.$set(v, 'status', null);
        this.$set(v, 'pageId', this.pageId);
      });
      this.content = _shuffle(this.itemsNormalized);
    },
    textClicked(itemClicked) {
      const isCorrect = itemClicked.id === this.correctId,
        clickedKey = _findIndex(this.content, (item) => item.id === itemClicked.id),
        correctKey = _findIndex(this.content, (item) => item.id === this.correctId),
        prevIncorrect = _findIndex(this.incorrect, (item) => item.pageId === this.pageId) > -1,
        prevCorrect = _findIndex(this.correct, (item) => item.pageId === this.pageId) > -1,
        audio = isCorrect ? Correct : Incorrect;
      this.content[clickedKey].status = isCorrect;

      if (!prevIncorrect && !prevCorrect) {
        if (isCorrect) {
          this.correct.push(this.content[correctKey]);
        } else {
          this.incorrect.push(this.content[correctKey]);
        }
        saveProgress(this.takeId, this.correctId, isCorrect);
      }

      if (isCorrect && this.pageId > this.completedPage) {
        this.completedPage = this.pageId;
      }

      if (this.$store.state.enableFeedbackSounds) {
        if (audio.currentTime !== 0) {
          audio.currentTime = 0;
        }

        audio.play();
      }

      _delay(this.statusComplete, 750, clickedKey, isCorrect);
    },
    statusComplete(contentKey, goNext) {
      this.content[contentKey].status = null;

      if (goNext) {
        if (this.pageId + 1 === this.qtyPages) {
          // This is the final page.
          this.showResults = true;
        } else {
          this.pageId++;
        }
      }
    },
  },
};
</script>

<style module>
.contentDisplay {
  width: 100%;
}
.progressStats {
  text-align: right;
}
</style>
