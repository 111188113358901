<template>
  <div class="container-fluid" :class="$style.results">
    <div class="row" :class="$style.resultItems">
      <div
        v-if="correct.length > 0"
        class="col-12 col-md-6 order-2 order-md-1 mt-sm-4 mt-md-0"
        :class="{ 'col-md-12': incorrect.length === 0, [$style.column]: true }"
      >
        <h1>{{ $t('correct') }}</h1>
        <div>{{ correct.length }} / {{ qtyTotal }}: {{ correctScore }}%</div>
        <ImageGrid show-text :items="correct" :fixed-height="250" />
      </div>
      <div
        v-if="incorrect.length > 0"
        class="col-12 col-md-6 order-1 order-md-2"
        :class="{ 'col-md-12': correct.length === 0, [$style.column]: true }"
      >
        <h1>{{ $t('incorrect') }}</h1>
        <div>{{ incorrect.length }} / {{ qtyTotal }}: {{ incorrectScore }}%</div>
        <ImageGrid show-text :items="incorrect" :fixed-height="250" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageGrid from './ImageGrid';

export default {
  components: { ImageGrid },
  props: {
    correct: {
      type: Array,
      required: true,
    },
    incorrect: {
      type: Array,
      required: true,
    },
  },
  computed: {
    qtyTotal() {
      return this.correct.length + this.incorrect.length;
    },
    correctScore() {
      if (this.correct.length === 0) {
        return 0;
      }
      return Math.round((this.correct.length / this.qtyTotal) * 100);
    },
    incorrectScore() {
      if (this.incorrect.length === 0) {
        return 0;
      }

      return Math.round((this.incorrect.length / this.qtyTotal) * 100);
    },
  },
};
</script>

<style module>
.resultItems {
  text-align: center;
  height: 100%;
}
.results {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.column {
  display: flex;
  flex-direction: column;
}
</style>
