var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", class: { "full-width": _vm.fullWidth } },
    [
      _c(
        "div",
        { staticClass: "col-12 col-md-6 col-sm-8 lesson-categories" },
        _vm._l(_vm.categories, function(c, k) {
          return _c(
            "MainMenuItem",
            {
              key: k,
              attrs: {
                to: { name: "lesson_category", params: { categoryId: c.id } }
              }
            },
            [
              c.asset.id
                ? _c("ImageDisplay", {
                    staticClass: "border-0 p-0 rounded-0 flex-shrink-0",
                    attrs: {
                      content: { display: c.asset },
                      autosize: 75,
                      "no-video": "",
                      "display-only": "",
                      "no-border": ""
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("_lesson_category_" + c.id)) +
                    "\n      "
                )
              ])
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-md-6 col-sm-4 app-routes" },
        [
          _vm.authEnabled
            ? [
                !_vm.isLoggedIn
                  ? _c(
                      "MainMenuItem",
                      {
                        staticClass: "col-12",
                        attrs: { to: { name: "login" } }
                      },
                      [
                        _c("FaIcon", { attrs: { icon: "sign-in-alt" } }),
                        _vm._v(
                          "\n        " + _vm._s(_vm.$t("login")) + "\n      "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isLoggedIn
                  ? _c(
                      "MainMenuItem",
                      { staticClass: "col-12", on: { click: _vm.logOut } },
                      [
                        _c("FaIcon", { attrs: { icon: "sign-out-alt" } }),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("logout_of", [_vm.usernameUser])) +
                            "\n      "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "MainMenuItem",
            { staticClass: "col-12", attrs: { to: { name: "settings" } } },
            [
              _c("FaIcon", { attrs: { icon: "cog" } }),
              _vm._v("\n      " + _vm._s(_vm.$t("settings.title")) + "\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _vm.isMobile
            ? _c(
                "MainMenuItem",
                {
                  staticClass: "col-12",
                  attrs: { to: { name: "offline_mgt" } }
                },
                [
                  _c("FaIcon", { attrs: { icon: "download" } }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("mobile.download_lessons")) +
                      "\n    "
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "MainMenuItem",
            { staticClass: "col-12", attrs: { to: { name: "photo_credits" } } },
            [
              _c("FaIcon", { attrs: { icon: "images" } }),
              _vm._v("\n      " + _vm._s(_vm.$t("photo_credits")) + "\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.pages, function(p) {
            return _c(
              "MainMenuItem",
              {
                key: p.id,
                staticClass: "col-12",
                attrs: { to: { name: "content_page", params: { id: p.id } } }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("_content_page_title_" + p.id)) +
                    "\n      "
                ),
                p.state === 0 ? _c("em", [_vm._v(" (Draft)")]) : _vm._e()
              ]
            )
          }),
          _vm._v(" "),
          _vm.enableContact
            ? _c(
                "MainMenuItem",
                { staticClass: "col-12", attrs: { to: { name: "contact" } } },
                [
                  _c("FaIcon", { attrs: { icon: "address-card" } }),
                  _vm._v("\n      " + _vm._s(_vm.$t("contact")) + "\n    ")
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }