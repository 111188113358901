<template>
  <div class="progress rounded-0 my-1" :class="$style.progress">
    <div class="progress-bar bg-success" :style="progressStyle" />
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    progressStyle() {
      return {
        width: `${this.progress}%`,
      };
    },
  },
};
</script>

<style module>
.progress {
  height: 2px;
  background-color: transparent;
}
</style>
