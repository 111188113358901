import './MainShared';
import Vue from 'vue';

import router from 'client/Router';

import store from 'store/store';
import i18n from 'i18n';
import App from 'App';

import ga from 'config/ga';
ga(router);

import { init } from 'config/main';

init(false).then();

new Vue({
  router,
  store,
  i18n,
  el: '#app',
  render: (createElement) => createElement(App),
});
