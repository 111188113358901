<template>
  <div ref="container" class="sized-area">
    <slot />
  </div>
</template>

<script>
import _throttle from 'lodash/throttle';
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({ height: 100, width: 100, ratio: 1 }),
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    this.resize();
    this.resizeThrottled = _throttle(this.resize, 100);
    window.addEventListener('resize', this.resizeThrottled);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeThrottled);
  },
  methods: {
    resize() {
      if (!this.enabled) {
        return;
      }
      let width = this.$refs.container.clientWidth,
        boundingRect = this.$refs.container.getBoundingClientRect(),
        viewPortHeight = document.documentElement.clientHeight,
        parentHeight = this.$el.parentElement.clientHeight,
        below = parentHeight - boundingRect.height,
        height = viewPortHeight - boundingRect.top - below;
      // console.log({
      //     boundingRect, below, height, width, parentHeight, this: this,
      // });

      this.$el.style.height = Math.round(height) + 'px';

      let ratio = Math.round((width / height) * 100) / 100;

      this.$emit('input', {
        height,
        width,
        ratio,
      });
    },
    resizeThrottled() {
      // Overwritten in the mounted event.
    },
  },
};
</script>
