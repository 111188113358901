import correct from '../ui_assets/correct.mp3';
import incorrect from '../ui_assets/incorrect.mp3';

import { Howl } from 'howler';

export const Correct = new Howl({
  src: [correct],
  volume: 0.2,
});

export const Incorrect = new Howl({
  src: [incorrect],
  volume: 0.2,
});
