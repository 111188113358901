<template>
  <div class="input-feedback" :class="{ show: visible, small: small }">
    <span class="dummy-child" />
    <span v-if="successState" class="status correct"><FaIcon icon="check-circle" /></span>
    <span v-if="!successState" class="status incorrect"><FaIcon icon="times-circle" /></span>
  </div>
</template>

<script>
export default {
  props: {
    success: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      successState: null,
    };
  },
  watch: {
    success: {
      handler(newVal) {
        if (newVal !== null) {
          // Only change the value if it isn't "reset".
          this.successState = newVal;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.input-feedback {
  opacity: 0;
  transition: opacity 200ms ease-in-out, background-color ease-in-out 100ms;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  text-align: center;
  font-size: 4rem;
  &.small {
    font-size: 2rem;
  }

  &.show {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .dummy-child {
    height: 90%;
  }

  .dummy-child,
  .status {
    display: inline-block;
    vertical-align: middle;
  }

  .status {
    &.correct {
      color: green;
    }
    &.incorrect {
      color: red;
    }
  }
}
</style>
