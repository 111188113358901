var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    {
      attrs: { "enable-menu": false, fluid: false },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("h1", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("welcome_to", {
                        learn_lang_name: _vm.$t("learn_lang_name")
                      })
                    ) +
                    "\n    "
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [_vm._v(" "), _c("MainMenu")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }