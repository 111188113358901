var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c(
        "div",
        { staticClass: "text-center", class: _vm.classes },
        [_c("FaIcon", { attrs: { icon: "spinner", spin: "" } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }