import Vue from 'vue';

import _camelCase from 'lodash/camelCase';
import _isBoolean from 'lodash/isBoolean';
import _isNumber from 'lodash/isNumber';
import _find from 'lodash/find';
import _isObject from 'lodash/isObject';

import {
  LANGUAGES,
  LESSONS,
  LESSON,
  ACTIVITIES,
  TRANSLATIONS_LOADING,
  IMAGES,
  IMAGES_LOADING,
  SETTINGS,
  LESSON_CATEGORIES,
  CONTENT_PAGES_LIST,
} from './mutationTypes';
import { load as languageData } from '../data/Language';
import { load as lessonData } from 'data/Lesson';
import { load as activityData } from '../data/Activity';
import { load as imageData } from 'data/Images';

const LANGUAGES_LOADING = 'LANGUAGES_LOADING';
const LESSONS_LOADING = 'LESSONS_LOADING';
const ACTIVITIES_LOADING = 'ACTIVITIES_LOADING';

export function buildLoader(loader, dataMutation, loadingMutation) {
  return function ({ state, commit }, payload) {
    let id,
      force,
      hasId = false;

    if (_isObject(payload)) {
      if (payload.id && _isNumber(payload.id)) {
        id = payload.id;
        hasId = true;
      }
      if (payload.force !== undefined) {
        force = payload.force;
      }
    } else if (_isNumber(payload)) {
      hasId = true;
      id = payload;
    } else if (_isBoolean(payload)) {
      force = payload;
    }

    let stateKey = _camelCase(dataMutation);

    if (hasId) {
      if (!force && state[stateKey][id]) {
        return Promise.resolve(state[stateKey][id]);
      }
    } else {
      if (!force && state[stateKey]) {
        return Promise.resolve(state[stateKey]);
      }
    }

    if (hasId) {
      commit(loadingMutation, [id, true]);
    } else {
      commit(loadingMutation, true);
    }

    console.log(`Loading '${dataMutation}' for id '${id}'`);
    const promise = loader({
      id: id,
      force: force,
    });

    promise
      .then((res) => {
        commit(dataMutation, res);
        if (hasId) {
          commit(loadingMutation, [id, false]);
        } else {
          commit(loadingMutation, false);
        }
        return res;
      })
      .catch((err) => {
        if (hasId) {
          commit(loadingMutation, [id, false]);
        } else {
          commit(loadingMutation, false);
        }
        alert(err);
      });

    return promise;
  };
}

export default {
  state: {
    translationsLoading: true,
    settings: {},
    languages: undefined,
    languagesLoading: false,
    lessons: undefined,
    lessonsLoading: false,
    lessonCategories: [],
    contentPagesList: [],
    activities: {},
    activitiesLoading: {},
    images: undefined,
    imagesLoading: false,
  },
  mutations: {
    [SETTINGS](state, payload) {
      state.settings = payload;
    },
    [LANGUAGES](state, payload) {
      state.languages = payload;
    },
    [LANGUAGES_LOADING](state, payload) {
      state.languagesLoading = payload;
    },
    [LESSONS](state, payload) {
      state.lessons = payload;
    },
    [LESSONS_LOADING](state, payload) {
      state.lessonsLoading = payload;
    },
    [LESSON](state, payload) {
      let lesson = _find(state.lessons, (l) => l.id === payload.id);

      Vue.set(lesson, 'activities', payload.activities);
    },
    [ACTIVITIES](state, payload) {
      Vue.set(state.activities, payload.id, payload);
    },
    [ACTIVITIES_LOADING](state, payload) {
      Vue.set(state.activitiesLoading, payload[0], payload[1]);
    },
    [TRANSLATIONS_LOADING](state, payload) {
      state.translationsLoading = payload;
    },
    [IMAGES](state, payload) {
      state.images = payload;
    },
    [IMAGES_LOADING](state, payload) {
      state.imagesLoading = payload;
    },
    [LESSON_CATEGORIES](state, payload) {
      state.lessonCategories = payload;
    },
    [CONTENT_PAGES_LIST](state, payload) {
      state.contentPagesList = payload;
    },
  },
  actions: {
    loadLanguages: buildLoader(languageData, LANGUAGES, LANGUAGES_LOADING),
    loadLessons: buildLoader(lessonData, LESSONS, LESSONS_LOADING),
    loadActivity: buildLoader(activityData, ACTIVITIES, ACTIVITIES_LOADING),
    loadAssets: buildLoader(imageData, IMAGES, IMAGES_LOADING),
  },
  getters: {
    appLoading: (state) => {
      return state.lessonsLoading || state.translationsLoading;
    },
    activity: (state) => (activityId) => {
      if (state.activities[activityId] !== undefined) {
        return state.activities[activityId];
      }
      return {};
    },
    activityLoading: (state) => (activityId) => {
      if (state.activitiesLoading[activityId] === undefined) {
        return true;
      }
      return state.activitiesLoading[activityId];
    },
    setting: (state) => (settingId) => {
      if (state.settings[settingId] !== undefined) {
        return state.settings[settingId];
      }
    },
  },
};
