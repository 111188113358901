<template>
  <ClientLayout>
    <template #title>
      {{ $t('_lesson_' + lessonId) }}/{{ $t('_activity_' + activityId) }}
    </template>

    <template v-if="!isLoading && !showNextSteps">
      <SizedArea
        ref="sizedArea"
        v-model="size"
        v-touch:swipe="swipe"
        style="height: 100%; position: relative;"
        class="row"
      >
        <div style="position: absolute; top: 0; height: 100%; width: 100%;">
          <ImageDisplay
            v-for="(s, sid) in loadedSlides"
            v-show="slideId === sid"
            :key="sid"
            ref="slide"
            :content="s"
            :play-on-click="paused"
            :enable-play="paused"
            :video-audio-sync="paused"
            :width="imageSize.width"
            :style="imageStyle"
            class="mx-auto p-1"
            :class="$style.imageDisplay"
          />
          <ContentDisplay
            ref="content"
            :content="slides[slideId].content"
            class="m-3"
            style="position: absolute; left: 0; right: 0;"
          />
        </div>
      </SizedArea>
      <div class="row mb-1">
        <SequenceControls
          :paused="paused"
          :is-end="isEnd"
          :qty="qtySlides + 1"
          :current="progress"
          @playPauseClick="playPause"
          @advance="advance"
          @retreat="retreat"
        />
      </div>
    </template>
    <Loading v-if="loading" size="lg" center />
    <NextActivity
      v-if="showNextSteps"
      :lesson-id="lessonId"
      :activity-id="activityId"
      @repeat="restart"
    >
      <h1>{{ $t('activity_complete') }}</h1>
    </NextActivity>
  </ClientLayout>
</template>

<script>
import ClientLayout from '../ClientLayout';
import Lesson from 'mixins/Lessons';
import isRtl from 'mixins/IsRtl';
import ImageDisplay from '../components/ImageDisplay';
import ContentDisplay from 'components/ContentDisplay';
import SequenceControls from 'components/SequenceControls';
import Loading from 'components/Loading';
import SizedArea from '../components/SizedArea';

import { noSleep } from 'lib/NoSleep';
import Delay from 'lib/Delay';
import Audio from 'lib/Audio';
import { initializeAudio } from '../lib/initalizeAudio';
import NextActivity from '../components/NextActivity';
import { calculateSize, imageStyle } from '../lib/image';

const PRELOAD_QTY = 5;

export default {
  components: {
    NextActivity,
    SizedArea,
    ImageDisplay,
    ContentDisplay,
    ClientLayout,
    Loading,
    SequenceControls,
  },
  mixins: [Lesson, isRtl],
  props: {
    lessonId: {
      type: Number,
      required: true,
    },
    activityId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      slides: [],
      slideId: 0,
      maxSlideId: 0,
      preloadedSideId: -1,
      loading: true,
      showNextSteps: false,
      audioProgress: 0,

      playKey: 0,
      playKeyCurrent: 1,

      size: {
        height: null,
        width: null,
        ratio: null,
      },
    };
  },
  computed: {
    isLoading() {
      return this.lessonsLoading || this.loading;
    },
    paused() {
      return this.playKey !== this.playKeyCurrent;
    },
    activityData() {
      return this.$store.getters.activity(this.activityId);
    },
    currentSlide() {
      return this.slides[this.slideId];
    },
    currentSlideRef() {
      return this.$refs.slide[this.slideId];
    },
    currentAudio() {
      return this.slides[this.slideId].audio;
    },
    qtySlides() {
      return this.slides.length;
    },
    loadedSlides() {
      let end = this.maxSlideId + PRELOAD_QTY;

      if (end > this.qtySlides) {
        end = this.qtySlides;
      }

      return this.slides.slice(0, end);
    },
    progress() {
      let progress = this.slideId + 1;
      if (!this.paused) {
        // only show the partial progress when auto-playing.
        progress += this.audioProgress;
      }
      return progress;
    },
    isEnd() {
      return this.slideId + 1 >= this.slides.length && this.paused;
    },
    imageSize() {
      return calculateSize(this.size, this.currentSlide.display.metadata, this.$refs.content);
    },
    imageStyle() {
      return imageStyle(this.imageSize);
    },
  },
  watch: {
    slideId(newVal, oldVal) {
      if (newVal > oldVal && newVal > this.maxSlideId) {
        this.preloadAudio(newVal + PRELOAD_QTY - 1);

        this.maxSlideId = newVal;
      }
      this.$nextTick(() => {
        this.$refs.sizedArea.resize();
      });
    },
    paused: {
      handler(newVal) {
        if (newVal === false) {
          this.$log.debug('noSleep enabled');
          noSleep.enable();
        } else {
          this.$log.debug('noSleep disabled');
          noSleep.disable();
        }
      },
      immediate: false,
    },
  },
  mounted() {
    this.$store.dispatch('loadActivity', parseFloat(this.activityId)).then(() => {
      let slides = [];

      for (let pageId = 0; pageId < this.activityData.pages.length; pageId++) {
        const items = this.activityData.pages[pageId].items,
          msBefore = parseFloat(this.activityData.pages[pageId].config.before),
          msBetween = parseFloat(this.activityData.pages[pageId].config.between),
          msAfter = parseFloat(this.activityData.pages[pageId].config.after);
        for (let itemId = 0; itemId < items.length; itemId++) {
          const assets = items[itemId].assets;

          let display = [],
            audio = [];
          for (let assetId = 0; assetId < assets.length; assetId++) {
            const asset = assets[assetId];
            if (asset.type === 'd') {
              display.push(asset);
            } else if (asset.type === 'a') {
              audio.push(asset);
            }
          }

          const qtyDisplay = display.length,
            qtyAudio = audio.length;
          for (let displayId = 0; displayId < qtyDisplay; displayId++) {
            const isLast = displayId + 1 >= qtyDisplay;
            let a = [];

            if (qtyAudio === qtyDisplay) {
              a.push(audio[displayId]);
            } else if (qtyAudio > qtyDisplay) {
              // multiple audio per image
              let audioPerImage = Math.floor(qtyAudio / qtyDisplay);

              if (isLast && audioPerImage * qtyDisplay !== qtyAudio) {
                // Give the final slide the "exta" audio for non-whole number ratio.
                audioPerImage++;
              }

              for (let aId = 0; aId < audioPerImage; aId++) {
                const audioId = displayId * audioPerImage + aId;
                a.push(audio[audioId]);
              }
            } else {
              // re-use audio multiple times
              let audioId = displayId % qtyAudio;

              a.push(audio[audioId]);
            }

            slides.push({
              before: msBefore,
              display: display[displayId],
              audioList: a,
              audio: undefined,
              between: msBetween,
              after: msAfter,
              content: items[itemId].content,
            });
          }
        }
      }

      this.slides = slides;
      this.preloadAudio(PRELOAD_QTY - 1);
      this.loading = false;
      initializeAudio(this.play, this.pause);
    });
  },
  beforeDestroy() {
    // otherwise it keeps playing and doing weird things.
    this.pause();
    noSleep.disable();
  },
  methods: {
    restart() {
      this.showNextSteps = false;
      this.slideId = 0;
      this.play(0);
    },
    playPause() {
      if (this.paused && this.isEnd) {
        // reset.
        this.slideId = 0;
        this.play(0);
        return;
      }
      if (this.paused) {
        this.play(0);
      } else {
        this.pause();
        this.audioProgress = 0;
      }
    },
    pause() {
      this.playKey++;
      this.currentAudio.stop();
      this.currentSlideRef.videoStop();
    },
    async play(preDelay) {
      this.playKeyCurrent = this.playKey;
      let msBefore = this.currentSlide.before,
        msAfter = this.currentSlide.after;
      if (preDelay) {
        msBefore = preDelay;
      }

      await Delay(msBefore);

      if (this.paused) {
        return;
      }

      // Play current
      this.$log.debug('play', this.slideId);
      await this.currentAudio.play();

      if (this.isEnd || this.paused) {
        // Don't delay before the advance so the replay button is immediately shown.
        return;
      }

      // Delay before advance.
      await Delay(msAfter);

      if (!this.paused && this.slideId + 1 >= this.slides.length) {
        // We're at the end!
        this.pause();
        this.showNextSteps = true;
        return;
      }
      if (this.paused) {
        return;
      }

      this.advance();

      if (this.paused) {
        return;
      }

      return this.play();
    },
    advance() {
      this.audioProgress = 0;
      if (!this.isEnd) {
        this.currentAudio.stop();
        this.currentSlideRef.videoStop();
        this.slideId++;
      }
    },
    retreat() {
      this.audioProgress = 0;
      if (this.slideId > 0) {
        this.currentAudio.stop();
        this.currentSlideRef.videoStop();
        this.slideId--;
      }
    },
    swipe(direction) {
      if (!this.paused) {
        return;
      }

      const swapper = { left: 'right', right: 'left' };

      if (!swapper[direction]) {
        // up and down swipe directions are ignored.
        return;
      }

      if (this.isNavRtl) {
        direction = swapper[direction];
      }

      if (direction === 'right') {
        this.retreat();
      } else {
        this.advance();
      }
    },
    preloadAudio(throughSlideId) {
      for (let i = this.preloadedSideId + 1; i <= throughSlideId; i++) {
        if (this.slides[i] === undefined) {
          // past the end or already loaded.
          break;
        }

        if (this.slides[i].audio !== undefined) {
          continue;
        }

        this.slides[i].audio = this.createAudio(i);
      }
      this.preloadedSideId = throughSlideId;
    },
    createAudio(slideId) {
      const slide = this.slides[slideId];
      return new Audio(slide.audioList, slide.between, {
        onprogress: (pct) => {
          if (pct < 1 && slideId === this.slideId) {
            this.audioProgress = pct;
          }
        },
      });
    },
  },
};
</script>

<style module>
.imageDisplay {
  width: 100%;
  height: 100%;
}
</style>
