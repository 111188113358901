var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ClientLayout",
    { attrs: { fluid: false } },
    [
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _vm.category.asset.id
            ? _c("ImageDisplay", {
                attrs: {
                  content: { display: _vm.category.asset },
                  autosize: 200,
                  "no-video": "",
                  "display-only": ""
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h1", { staticClass: "mb-0" }, [
            _vm._v(_vm._s(_vm.$t("_lesson_category_" + _vm.categoryId)))
          ]),
          _vm._v(" "),
          _vm.credits
            ? _c("div", {
                class: _vm.$style.credits,
                domProps: { innerHTML: _vm._s(_vm.credits) }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("LessonCategoryMenu", {
        staticClass: "col-sm-12 col-md-8 col-lg-6 mb-5",
        attrs: { lessons: _vm.lessons, "category-id": _vm.categoryId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }