<template>
  <ClientLayout>
    <template #title>
      {{ $t('_lesson_' + lessonId) }}/{{ $t('_activity_' + activityId) }}
    </template>
    <template v-if="!activityLoading" #toolbar>
      <PageNavigation ref="navigation" v-model="pageId" :qty-pages="qtyPages" />
    </template>

    <template v-if="!activityLoading">
      <ProgressBar :progress="progressPct" />

      <ImageGrid
        :key="pageId"
        v-touch:swipe="swipe"
        class="mt-3"
        :items="itemsNormalized"
        :play-on-click="true"
      />
    </template>
  </ClientLayout>
</template>

<script>
import ClientLayout from '../ClientLayout';
import ImageGrid from '../components/ImageGrid';
import PageNavigation from '../components/PageNavigation';

import Activity from 'mixins/Activity';
import IsRtl from 'mixins/IsRtl';
import ProgressBar from '../components/ProgressBar';

export default {
  components: { ProgressBar, ClientLayout, ImageGrid, PageNavigation },
  mixins: [Activity, IsRtl],
  computed: {
    progressPct() {
      if (this.pageId === 0) {
        return 0;
      }

      return Math.round((this.pageId / (this.qtyPages - 1)) * 100);
    },
  },
  methods: {
    swipe(direction) {
      if (direction === 'right') {
        this.$refs.navigation.clickLeft();
      } else if (direction === 'left') {
        this.$refs.navigation.clickRight();
      }
    },
  },
};
</script>
