import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { load } from 'data/Translation';

Vue.use(VueI18n);

import store from 'store/store';
import { LOCALE, TRANSLATIONS_LOADING } from './store/mutationTypes';

const i18n = new VueI18n({
  locale: store.state.locale,
  fallbackLocale: 'en',
});

export default i18n;

const loadedLanguages = [];

function setLang(lang) {
  if (lang === i18n.locale) {
    return;
  }
  if (!loadedLanguages.includes(lang)) {
    LoadTranslations(lang).then(() => {
      i18n.locale = lang;
    });
  } else {
    i18n.locale = lang;
  }
}

export function LoadTranslations(lang) {
  store.commit(TRANSLATIONS_LOADING, true);
  return load({ id: lang }).then((translations) => {
    SetTranslation(lang, translations);
    // Should be last
    store.commit(TRANSLATIONS_LOADING, false);
    return translations;
  });
}

/**
 * So that translations can be loaded and set from outside i18n
 * @param lang String
 * @param translations Object
 */
export function SetTranslation(lang, translations) {
  if (!loadedLanguages.includes(lang)) {
    loadedLanguages.push(lang);
  }
  i18n.setLocaleMessage(lang, translations);
}

export function MergeTranslation(locale, translations) {
  i18n.mergeLocaleMessage(locale, translations);
}

store.subscribe((mutation) => {
  if (mutation.type === LOCALE) {
    setLang(mutation.payload);
  }
});
