import Vue from 'vue';

import '../styles/feedback_animation.scss';

export default Vue.directive('feedback-animation', {
  bind(el) {
    el.classList.add('feedbackAnimation');
  },
  update(el, binding) {
    el.classList.remove('feedbackBounce');
    el.classList.remove('feedbackShake');

    if (binding.value === true) {
      el.classList.add('feedbackBounce');
    } else if (binding.value === false) {
      el.classList.add('feedbackShake');
    }
  },
});
