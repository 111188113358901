var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    { attrs: { fluid: false } },
    [
      !_vm.langLoading
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.$t("settings.title")))])
                ])
              ]),
              _vm._v(" "),
              _c(
                "BForm",
                { attrs: { inline: "" } },
                [
                  _c(
                    "BFormCheckbox",
                    {
                      model: {
                        value: _vm.enableFeedbackSounds,
                        callback: function($$v) {
                          _vm.enableFeedbackSounds = $$v
                        },
                        expression: "enableFeedbackSounds"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("settings.enable_feedback_sounds")) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.langData.length > 1
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.locale,
                              expression: "locale"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.locale = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.langData, function(l) {
                          return _c(
                            "option",
                            { key: l, domProps: { value: l } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("_language_" + l)) +
                                  "\n        "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "BButton",
                { attrs: { variant: "primary", to: { name: "index" } } },
                [
                  _c("FaIcon", { attrs: { icon: "save" } }),
                  _vm._v(" " + _vm._s(_vm.$t("save")) + "\n    ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.langLoading
        ? _c("Loading", { attrs: { size: "lg", center: "" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }