<template>
  <Layout :enable-menu="false" :fluid="false">
    <template #title>
      <h1>
        {{ $t('welcome_to', { learn_lang_name: $t('learn_lang_name') }) }}
      </h1>
    </template>

    <MainMenu />
  </Layout>
</template>

<script>
import Layout from 'ClientLayout';
import MainMenu from '../components/MainMenu';

export default {
  components: { MainMenu, Layout },
};
</script>
