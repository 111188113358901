<template>
  <ClientLayout>
    <template #title>
      {{ $t('_lesson_' + lessonId) }}/{{ $t('_activity_' + activityId) }}
    </template>
    <template v-if="!activityLoading" #toolbar>
      <div class="mb-2">
        <PageNavigation
          v-if="!showResults"
          v-model="pageId"
          class="float-left"
          :qty-pages="qtyPages"
          :allow-forward="pageId <= completedPage"
        />
        <ProgressStats
          class="float-right"
          :correct="correct.length"
          :total="correct.length + incorrect.length"
          :score="currentScore"
        />
      </div>
    </template>
    <template v-if="!activityLoading">
      <ProgressBar v-if="!showResults" :progress="progressPct" />

      <div v-if="!showResults" class="row justify-content-center" style="height: 100%;">
        <div class="word-bank col-12 col-md-6 col-lg-4">
          <Draggable
            v-model="wordBank"
            class="draggable border cornered p-1"
            group="main"
            :sort="false"
            :move="textMove"
            data-word-bank="true"
            @end="textEnd"
          >
            <ContentDisplay
              v-for="w in wordBank"
              :key="w.id"
              :content="w.content"
              :data-item-id="w.id"
            />
          </Draggable>
        </div>

        <div v-if="!showResults" class="images col">
          <ImageGrid :items="content">
            <div
              slot="header"
              slot-scope="{ item }"
              v-feedback-animation="statuses[item.id]"
              class="text-drop-container border cornered mt-2"
            >
              <Draggable
                v-model="answers[item.id]"
                class="text-drop-area"
                group="main"
                :sort="false"
                :move="textMove"
                :data-item-id="item.id"
                @end="textEnd"
              >
                <ContentDisplay
                  v-for="(i, k) in answers[item.id]"
                  :key="k"
                  :content="i.content"
                  :data-item-id="i.id"
                />
              </Draggable>
              <InputFeedback
                v-show="statuses[item.id] !== null"
                :visible="true"
                :success="statuses[item.id]"
                small
              />
            </div>
          </ImageGrid>
        </div>
      </div>

      <template v-if="showResults">
        <NextActivity :lesson-id="lessonId" :activity-id="activityId" @repeat="repeat" />
        <hr />
        <Results :correct="correct" :incorrect="incorrect" />
      </template>
    </template>
    <Loading v-model="activityLoading" size="lg" center />
  </ClientLayout>
</template>

<script>
import Lessons from 'mixins/Lessons';
import Activity from '../mixins/Activity';
import IsRtl from '../mixins/IsRtl';

import FeedbackAnimation from '../directives/FeedbackAnimation';

import ClientLayout from '../ClientLayout';
import PageNavigation from '../components/PageNavigation';
import ProgressStats from '../components/ProgressStats';
import ProgressBar from '../components/ProgressBar';
import ImageGrid from '../components/ImageGrid';
import ContentDisplay from '../components/ContentDisplay';
import InputFeedback from '../components/InputFeedback';
import Draggable from 'vuedraggable';
import Results from '../components/Results';
import Loading from '../components/Loading';

import _shuffle from 'lodash/shuffle';
import _clone from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _delay from 'lodash/delay';

import { Correct, Incorrect } from '../helpers/FeedbackSounds';
import NextActivity from '../components/NextActivity';
import { saveProgress } from '../data/Result';

export default {
  components: {
    NextActivity,
    ClientLayout,
    PageNavigation,
    ProgressStats,
    ProgressBar,
    ImageGrid,
    ContentDisplay,
    InputFeedback,
    Draggable,
    Results,
    Loading,
  },
  directives: { FeedbackAnimation },
  mixins: [Activity, Lessons, IsRtl],
  data() {
    let answers = [];

    for (let i = 0; i < 10; i++) {
      answers[i] = [];
    }

    return {
      content: [],
      wordBank: [],
      answers: {},
      statuses: {},
      completedPage: -1,
      correct: [],
      incorrect: [],
      pageStatus: {},
      showResults: false,
      takeId: Date.now(),
    };
  },
  computed: {
    currentScore() {
      if (this.correct.length === 0) {
        return 0;
      }
      return Math.round(
        (this.correct.length / (this.correct.length + this.incorrect.length)) * 100
      );
    },
    progressPct() {
      const qtyComplete = this.correct.length + this.incorrect.length;
      if (qtyComplete === 0) {
        return 0;
      }
      return Math.round((qtyComplete / this.qtyContent) * 100);
    },
  },
  watch: {
    pageId() {
      this.refreshContent();
    },
  },
  mounted() {
    this.$on('activityLoaded', () => {
      this.refreshContent();
    });
  },
  methods: {
    repeat() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.refreshContent();
    },
    textMove(e) {
      if ('wordBank' in e.to.dataset) {
        // we moved into the word bank, don't check anything.
        return;
      }

      if (e.relatedContext.list.length > 0) {
        // Don't allow dragging into list that already has a word.
        return false;
      }

      return true;
    },
    textEnd(e) {
      if ('wordBank' in e.to.dataset) {
        // We moved into the word bank, don't check anything.
        return;
      }

      const imageId = parseInt(e.to.dataset.itemId),
        textId = parseInt(e.item.dataset.itemId),
        isCorrect = imageId === textId,
        prevIncorrect =
          _findIndex(this.incorrect, (item) => item.id === textId && item.pageId === this.pageId) >
          -1,
        prevCorrect =
          _findIndex(this.correct, (item) => item.id === textId && item.pageId === this.pageId) >
          -1,
        audio = isCorrect ? Correct : Incorrect;
      let goNext = false;

      this.statuses[imageId] = isCorrect;
      this.pageStatus[textId] = isCorrect;

      if (!prevCorrect && !prevIncorrect) {
        if (isCorrect) {
          // If I don't clone this it does some crazy things with the draggable component.
          // I believe it is likely a bug in Vue or VueDraggable, but am not sure.
          this.correct.push(_clone(this.answers[imageId][0]));
        } else {
          this.incorrect.push(this.answers[imageId][0]);
        }
        saveProgress(this.takeId, this.answers[imageId][0].id, isCorrect);
      }

      if (this.wordBank.length === 0) {
        let allCorrect = true;
        for (let id in this.pageStatus) {
          allCorrect = allCorrect && this.pageStatus[id] === true;
        }
        if (allCorrect && this.pageId > this.completedPage) {
          // Mark this page as completed if all are correct and this is the first time to
          // complete it.
          this.completedPage = this.pageId;
        }
        goNext = allCorrect;
      }

      if (this.$store.state.enableFeedbackSounds) {
        if (audio.currentTime !== 0) {
          audio.currentTime = 0;
        }

        audio.play();
      }

      _delay(this.statusComplete, 750, imageId, goNext);
    },
    statusComplete(itemId, goNext) {
      this.statuses[itemId] = null;
      if (goNext) {
        if (this.pageId + 1 >= this.qtyPages) {
          this.showResults = true;
        } else {
          this.pageId++;
        }
      }
    },
    refreshContent() {
      this.pageStatus = {};
      this.itemsNormalized.forEach((item) => {
        // must be defined on the content as Vue can't detect property additions at runtime.
        // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
        this.$set(item, 'pageId', this.pageId);
        this.$set(this.answers, item.id, []);
        this.$set(this.statuses, item.id, null);
        this.$set(this.pageStatus, item.id, false);
      });

      this.content = _shuffle(this.itemsNormalized);
      this.wordBank = _shuffle(this.itemsNormalized);
    },
  },
};
</script>

<style scoped lang="scss">
.rtl {
  .word-bank {
    text-align: right;
  }
}
.word-bank {
  .draggable {
    min-height: 10rem;
  }
  .content-display {
    /* So the are each on a separate row. */
    display: block;
  }
}
.progress-stats {
  text-align: right;
}
.content-display {
  cursor: move;
}
.images {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}
.text-drop-area {
  height: 100%;
}
.text-drop-container {
  height: 48px;
  min-width: 100px;
  max-width: 100%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  background-color: lightgray;
  overflow: hidden;

  .input-feedback {
    margin-top: -9px;
  }
}
</style>
