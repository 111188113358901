<template>
  <div
    v-feedback-animation="content.status"
    class="image-display"
    :class="{
      'has-audio': hasAudio,
      selectable: alwaysSelectable,
      hover: showPlayIcon,
    }"
    :style="autoSizeStyle"
    @click="click"
    @mouseover="hasHover = true"
    @mouseout="hasHover = false"
  >
    <img
      v-if="subType === 'i' || disableVideo"
      class="rounded fill-space"
      :class="{ border: !noBorder }"
      :src="imageSrc"
      @ondragstart="false"
      @ondrop="false"
      @mousedown="mouseDown"
    />
    <video
      v-if="subType === 'v' && !disableVideo"
      ref="video"
      class="rounded fill-space"
      :class="{ border: !noBorder }"
      :poster="imageSrc"
      preload="auto"
      muted
      :controls="videoControls && hasHover"
      @mousedown="mouseDown"
    >
      <source v-if="videoSrc" :src="videoSrc" />
    </video>

    <FaIcon v-if="videoIcon && subType === 'v'" icon="video" class="rounded" />
    <InputFeedback
      v-if="!videoControls && !displayOnly"
      class="rounded"
      :visible="showFeedback"
      :success="content.status"
    />
    <PlayIcon
      v-if="!displayOnly"
      v-show="hasAudio"
      ref="playIcon"
      class="play-icon"
      :audio="content.audio"
      :enable-play="enablePlay"
      @play-start="playStart"
      @play-end="playEnd"
    />
  </div>
</template>

<script>
import PlayIcon from './PlayIcon';
import InputFeedback from './InputFeedback';
import FeedbackAnimation from '../directives/FeedbackAnimation';
import { assetPath, videoScaleFactor } from '../helpers/Media';
import _sort from 'lodash/sortBy';

export default {
  components: { PlayIcon, InputFeedback },
  directives: { FeedbackAnimation },
  props: {
    content: {
      type: Object,
      required: true,
    },
    playOnClick: {
      type: Boolean,
      default: true,
      required: false,
    },
    enablePlay: {
      type: Boolean,
      default: true,
      required: false,
    },
    width: {
      type: Number,
      default: 0,
      /**
       * This is required to be able to select the correct resolution video. If omitted,
       * video playback will be disabled.
       */
      required: false,
    },
    /**
     * False to disable, number to specify fixed height.
     */
    autosize: {
      type: [Boolean, Number],
      default: false,
      required: false,
    },
    /**
     * If the image has a pointer cursor.
     */
    alwaysSelectable: {
      type: Boolean,
      default: false,
      required: false,
    },
    noVideo: {
      type: Boolean,
      default: false,
      required: false,
    },
    videoControls: {
      type: Boolean,
      default: false,
      required: false,
    },
    videoIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
    videoAudioSync: {
      type: Boolean,
      default: true,
      required: false,
    },
    /**
     * No feedback, playback, etc.
     */
    displayOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      hasHover: false,
    };
  },
  computed: {
    hasAudio() {
      if (!this.playOnClick) {
        return false;
      }

      return this.content.audio && this.content.audio !== null;
    },
    metadata() {
      if (!this.content.display.metadata) {
        return {};
      }

      return this.content.display.metadata;
    },
    imageSrc() {
      if (this.subType === 'i') {
        return assetPath(this.content.display.name);
      } else if (this.subType === 'v' && this.metadata.poster) {
        return assetPath(this.metadata.poster);
      }
      return '';
    },
    disableVideo() {
      if (this.noVideo === true) {
        return true;
      }

      return this.videoSrc.length === 0;
    },
    videoSizes() {
      if (!this.metadata.sizes) {
        return [];
      }
      return _sort(this.metadata.sizes, [(s) => s.width]);
    },
    videoSrc() {
      if (this.subType !== 'v' || !this.width) {
        return '';
      }

      let src = '',
        // allow it to scape up a certain pecentage.
        minSize = this.width * videoScaleFactor;
      for (let sizeId = 0; sizeId < this.videoSizes.length; sizeId++) {
        const size = this.videoSizes[sizeId];

        if (size.width >= minSize) {
          src = assetPath(size.name);
          break;
        }
      }

      if (src === '') {
        // if we don't have a good match, just grab the largest available.
        src = assetPath(this.videoSizes[this.videoSizes.length - 1].name);
      }

      return src;
    },
    showFeedback() {
      return this.content.status === true || this.content.status === false;
    },
    showPlayIcon() {
      if (!this.playOnClick || this.displayOnly) {
        return false;
      }
      let playIcon = this.$refs.playIcon;
      // We are hovering or audio is playing.
      return this.hasHover || (playIcon !== undefined && playIcon.playing);
    },
    subType() {
      return this.content.display.subtype;
    },
    autoSizeStyle() {
      if (this.autosize === true || this.autosize > 0) {
        let width = Math.round(this.autosize * this.metadata.ratio);
        return {
          height: this.autosize + 'px',
          width: width + 'px',
        };
      }
      return {};
    },
  },
  methods: {
    click(e) {
      if (this.displayOnly && !this.alwaysSelectable) {
        return;
      }
      e.stopPropagation();
      this.$emit('image-click');
      this.play();
    },
    play() {
      if (!this.hasAudio) {
        return;
      }
      let audio = this.$refs.playIcon;
      if (!audio) {
        return;
      }
      return audio.play();
    },
    playStart() {
      if (this.subType === 'v' && this.$refs.video) {
        this.$refs.video.currentTime = 0;
        this.$refs.video.play();
      }
      this.$emit('play-start');
    },
    playEnd() {
      if (this.subType === 'v' && this.videoAudioSync && this.$refs.video) {
        this.$refs.video.pause();
      }
      this.$emit('play-end');
    },
    videoStop() {
      if (this.subType === 'v' && this.$refs.video) {
        this.$refs.video.pause();
      }
    },
    mouseDown(e) {
      // Right click.
      if (e.button === 2) {
        e.preventDefault();
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../styles/mixins';
.image-display {
  /* needed so the play icon positions correctly */
  position: relative;
  text-align: center;

  &.hover .play-icon {
    opacity: 1;
  }

  &.hover:hover img {
    @include box-shadow;
  }
  &.hover:hover video {
    @include box-shadow;
  }

  .fill-space {
    height: 100%;
    width: 100%;
  }
  /* Make it at least a little difficult for users to save images. */
  img,
  video {
    /*pointer-events: none;*/
  }
}
.fa-video {
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.75);
  padding-left: 2px;
  padding-right: 2px;
  position: absolute;
  bottom: 7px;
  right: 7px;
}
.play-icon {
  opacity: 0;
  transition: opacity 250ms;
  position: absolute;
  top: 8px;
  left: 0.5rem;
}
.has-audio,
.selectable {
  cursor: pointer;
}
.status {
  position: absolute;
  left: 0;
  top: 40%;
  width: 100%;
  font-size: 4em;
}
.incorrect {
  color: red;
}
</style>
