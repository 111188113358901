var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid", class: _vm.$style.results },
    [
      _c("div", { staticClass: "row", class: _vm.$style.resultItems }, [
        _vm.correct.length > 0
          ? _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-6 order-2 order-md-1 mt-sm-4 mt-md-0",
                class:
                  ((_obj = { "col-md-12": _vm.incorrect.length === 0 }),
                  (_obj[_vm.$style.column] = true),
                  _obj)
              },
              [
                _c("h1", [_vm._v(_vm._s(_vm.$t("correct")))]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.correct.length) +
                      " / " +
                      _vm._s(_vm.qtyTotal) +
                      ": " +
                      _vm._s(_vm.correctScore) +
                      "%"
                  )
                ]),
                _vm._v(" "),
                _c("ImageGrid", {
                  attrs: {
                    "show-text": "",
                    items: _vm.correct,
                    "fixed-height": 250
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.incorrect.length > 0
          ? _c(
              "div",
              {
                staticClass: "col-12 col-md-6 order-1 order-md-2",
                class:
                  ((_obj$1 = { "col-md-12": _vm.correct.length === 0 }),
                  (_obj$1[_vm.$style.column] = true),
                  _obj$1)
              },
              [
                _c("h1", [_vm._v(_vm._s(_vm.$t("incorrect")))]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.incorrect.length) +
                      " / " +
                      _vm._s(_vm.qtyTotal) +
                      ": " +
                      _vm._s(_vm.incorrectScore) +
                      "%"
                  )
                ]),
                _vm._v(" "),
                _c("ImageGrid", {
                  attrs: {
                    "show-text": "",
                    items: _vm.incorrect,
                    "fixed-height": 250
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }