var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ClientLayout",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("_lesson_" + _vm.lessonId)) +
                    "/" +
                    _vm._s(_vm.$t("_activity_" + _vm.activityId)) +
                    "\n  "
                )
              ]
            },
            proxy: true
          },
          !_vm.activityLoading
            ? {
                key: "toolbar",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        !_vm.showResults
                          ? _c("PageNavigation", {
                              staticClass: "float-left",
                              attrs: {
                                "qty-pages": _vm.qtyPages,
                                "allow-forward": _vm.allowForward
                              },
                              model: {
                                value: _vm.pageId,
                                callback: function($$v) {
                                  _vm.pageId = $$v
                                },
                                expression: "pageId"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("ProgressStats", {
                          staticClass: "float-right",
                          class: [_vm.$style.progressStats],
                          attrs: {
                            correct: _vm.correct.length,
                            total: _vm.completedPage + 1,
                            score: _vm.scorePct
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      !_vm.activityLoading
        ? [
            !_vm.showResults
              ? _c("ProgressBar", { attrs: { progress: _vm.progress } })
              : _vm._e(),
            _vm._v(" "),
            !_vm.showResults
              ? _c("ImageGrid", {
                  attrs: {
                    items: _vm.imageContent,
                    "play-on-click": false,
                    selectable: false
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "gridFooter",
                        fn: function() {
                          return [
                            _c(
                              "table",
                              { staticClass: "mx-auto" },
                              _vm._l(_vm.content, function(i) {
                                return _c("tr", { key: i.id }, [
                                  _c(
                                    "td",
                                    [
                                      _c("ContentDisplay", {
                                        staticClass: "my-1",
                                        class: [_vm.$style.contentDisplay],
                                        attrs: {
                                          content: i.content,
                                          selectable: true,
                                          success: i.status
                                        },
                                        on: {
                                          "text-click": function($event) {
                                            return _vm.textClicked(i)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  i.audio
                                    ? _c(
                                        "td",
                                        [
                                          _c("play-icon", {
                                            staticClass: "ml-2",
                                            attrs: {
                                              audio: i.audio,
                                              "enable-play": !_vm.isPlaying
                                            },
                                            on: {
                                              "play-start": function($event) {
                                                _vm.isPlaying = true
                                              },
                                              "play-end": function($event) {
                                                _vm.isPlaying = false
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2983029059
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showResults
              ? [
                  _c("NextActivity", {
                    attrs: {
                      "lesson-id": _vm.lessonId,
                      "activity-id": _vm.activityId
                    },
                    on: { repeat: _vm.repeat }
                  }),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("Results", {
                    attrs: { correct: _vm.correct, incorrect: _vm.incorrect }
                  })
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("Loading", {
        attrs: { size: "lg", center: "" },
        model: {
          value: _vm.activityLoading,
          callback: function($$v) {
            _vm.activityLoading = $$v
          },
          expression: "activityLoading"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }