import { render, staticRenderFns } from "./PageNavigation.vue?vue&type=template&id=022f129a&scoped=true&"
import script from "./PageNavigation.vue?vue&type=script&lang=js&"
export * from "./PageNavigation.vue?vue&type=script&lang=js&"
import style0 from "./PageNavigation.vue?vue&type=style&index=0&id=022f129a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022f129a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/jeffreymb/BibleLing/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('022f129a')) {
      api.createRecord('022f129a', component.options)
    } else {
      api.reload('022f129a', component.options)
    }
    module.hot.accept("./PageNavigation.vue?vue&type=template&id=022f129a&scoped=true&", function () {
      api.rerender('022f129a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PageNavigation.vue"
export default component.exports