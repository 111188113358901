import api from '../helpers/Api';
import store from '../store/store';

export async function saveProgress(takeId, itemId, isCorrect) {
  if (!store.getters.isLoggedIn) {
    // If we aren't logged in, we can't record anything!
    return;
  }

  await api.post(`result`, {
    takeId,
    itemId,
    isCorrect,
  });
}
