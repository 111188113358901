import i18n from 'i18n';
import store from 'store/store';

const t = function () {
  return i18n.t.apply(i18n, arguments);
};

export default function (cb) {
  return new Promise((resolve) => {
    if (!store.state.data.translationsLoading) {
      resolve(cb(t));
    } else {
      const stop = store.watch(
        (state) => state.data.translationsLoading,
        (newVal) => {
          if (!newVal) {
            resolve(cb(t));
            stop();
          }
        }
      );
    }
  });
}
