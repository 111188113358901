<template>
  <div class="row" :class="{ 'full-width': fullWidth }">
    <div class="col-12 col-md-6 col-sm-8 lesson-categories">
      <MainMenuItem
        v-for="(c, k) in categories"
        :key="k"
        :to="{ name: 'lesson_category', params: { categoryId: c.id } }"
      >
        <ImageDisplay
          v-if="c.asset.id"
          class="border-0 p-0 rounded-0 flex-shrink-0"
          :content="{ display: c.asset }"
          :autosize="75"
          no-video
          display-only
          no-border
        />
        <div class="card-body">
          {{ $t('_lesson_category_' + c.id) }}
        </div>
      </MainMenuItem>
    </div>
    <div class="col-12 col-md-6 col-sm-4 app-routes">
      <template v-if="authEnabled">
        <MainMenuItem v-if="!isLoggedIn" :to="{ name: 'login' }" class="col-12">
          <FaIcon icon="sign-in-alt" />
          {{ $t('login') }}
        </MainMenuItem>
        <MainMenuItem v-if="isLoggedIn" class="col-12" @click="logOut">
          <FaIcon icon="sign-out-alt" />
          {{ $t('logout_of', [usernameUser]) }}
        </MainMenuItem>
      </template>
      <MainMenuItem :to="{ name: 'settings' }" class="col-12">
        <FaIcon icon="cog" />
        {{ $t('settings.title') }}
      </MainMenuItem>
      <MainMenuItem v-if="isMobile" class="col-12" :to="{ name: 'offline_mgt' }">
        <FaIcon icon="download" />
        {{ $t('mobile.download_lessons') }}
      </MainMenuItem>
      <MainMenuItem class="col-12" :to="{ name: 'photo_credits' }">
        <FaIcon icon="images" />
        {{ $t('photo_credits') }}
      </MainMenuItem>
      <MainMenuItem
        v-for="p in pages"
        :key="p.id"
        :to="{ name: 'content_page', params: { id: p.id } }"
        class="col-12"
      >
        {{ $t('_content_page_title_' + p.id) }}
        <em v-if="p.state === 0">&nbsp;(Draft)</em>
      </MainMenuItem>
      <MainMenuItem v-if="enableContact" class="col-12" :to="{ name: 'contact' }">
        <FaIcon icon="address-card" />
        {{ $t('contact') }}
      </MainMenuItem>
    </div>
  </div>
</template>

<script>
import ImageDisplay from '../components/ImageDisplay';
import MainMenuItem from './MainMenuItem';
import { mapGetters, mapState } from 'vuex';

import _find from 'lodash/find';
import Lessons from '../mixins/Lessons';
import { signOut } from '../config/Firebase';

export default {
  components: { MainMenuItem, ImageDisplay },
  mixins: [Lessons],
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile: IS_MOBILE,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapState(['usernameUser', 'authEnabled']),
    categories() {
      const allCats = this.$store.state.data.lessonCategories,
        categories = [];
      for (let i = 0; i < allCats.length; i++) {
        if (undefined !== _find(this.lessonData, (l) => l.category_id === allCats[i].id)) {
          // This category has a lesson, show it.
          categories.push(allCats[i]);
        }
      }

      return categories;
    },
    pages() {
      return this.$store.state.data.contentPagesList;
    },
    enableContact() {
      return this.$store.getters.setting('enableContact');
    },
  },
  methods: {
    async logOut() {
      await signOut();
    },
  },
};
</script>

<style scoped lang="scss">
.lesson-categories {
  .card {
    padding: 0;
    cursor: pointer;
  }
}
@media (min-width: 0px) {
  .full-width {
    // Disabled responsiveness inside the main menu.
    .lesson-categories,
    .app-routes {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
svg {
  margin: 4px 5px;
  font-size: 1.2rem;
}
</style>
