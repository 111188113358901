export function calculateSize(screenSize, metadata, headerFooterRef) {
  let width = 0,
    height = 0,
    contentHeight = 0;
  if (screenSize.ratio >= metadata.ratio) {
    height = screenSize.height;
    width = height * metadata.ratio;
  } else {
    width = screenSize.width;
    height = width / metadata.ratio;
  }

  if (headerFooterRef && headerFooterRef.$el.clientHeight) {
    contentHeight = headerFooterRef.$el.clientHeight;
  }
  const contentMargin = 38;

  if (contentHeight > 0 && height + contentHeight + contentMargin > screenSize.height) {
    height = screenSize.height - contentHeight - contentMargin;
    width = height * metadata.ratio;
  }
  return {
    width: Math.round(width),
    height: Math.round(height),
  };
}

export function imageStyle(imageSize) {
  let style = {};

  for (const k in imageSize) {
    style[k] = imageSize[k] + 'px';
  }

  return style;
}
