import api from 'helpers/Api';
import Vue from 'vue';
import Base from './BaseData';

export function load(opt) {
  return Base('contact/page', opt.force);
}

export async function submit(data) {
  Vue.$log.debug(data);
  try {
    const res = await api.post(`contact/submit`, data);

    return res.data;
  } catch (err) {
    Vue.$log.error(err);
    alert(err);

    return false;
  }
}
