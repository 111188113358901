<template>
  <ClientLayout :fluid="false">
    <div class="mt-3">
      <ImageDisplay
        v-if="category.asset.id"
        :content="{ display: category.asset }"
        :autosize="200"
        no-video
        display-only
      />
      <h1 class="mb-0">{{ $t('_lesson_category_' + categoryId) }}</h1>
      <div v-if="credits" :class="$style.credits" v-html="credits" />
    </div>
    <LessonCategoryMenu
      :lessons="lessons"
      :category-id="categoryId"
      class="col-sm-12 col-md-8 col-lg-6 mb-5"
    />
  </ClientLayout>
</template>

<script>
import Lessons from '../mixins/Lessons';
import ClientLayout from '../ClientLayout';
import ImageDisplay from '../components/ImageDisplay';
import LessonCategoryMenu from '../components/LessonMenu';

import _find from 'lodash/find';
import _filter from 'lodash/filter';

export default {
  components: { ImageDisplay, ClientLayout, LessonCategoryMenu },
  mixins: [Lessons],
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    credits() {
      const id = `_lesson_category_credits_${this.categoryId}`;
      if (this.$te(id)) {
        return this.$t(id);
      }
      return false;
    },
    category() {
      const cat = _find(this.$store.state.data.lessonCategories, (c) => c.id === this.categoryId);

      if (cat !== undefined) {
        return cat;
      }
      return { asset: {} };
    },
    lessons() {
      const lessons = _filter(this.lessonData, (l) => l.category_id === this.categoryId);
      if (!lessons) {
        return [];
      }
      return lessons;
    },
  },
};
</script>

<style module>
.credits {
  margin-left: 2em;
  margin-bottom: 0.5em;
  color: #8a8a8a;
}
</style>
