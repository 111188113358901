import IsRtl from './IsRtl';

export default {
  mixins: [IsRtl],
  computed: {
    contentStyle() {
      let style = {};

      if (this.isRtl) {
        style['direction'] = 'rtl';
      }
      const font = this.$store.getters.setting('contentFont');
      if (font) {
        style['font-family'] = font.replace(/'/g, '"');
      }

      const fontsize = this.$store.getters.setting('contentFontsize');
      if (fontsize) {
        style['font-size'] = `${fontsize}rem`;
      }

      return style;
    },
  },
};
