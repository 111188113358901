<template>
  <div id="app" :class="{ 'direction-rtl': isRtl, [$style.app]: true }">
    <RouterView :key="$route.path" />

    <div
      v-if="enableSwipeMenu"
      v-touch:swipe="swipe"
      :class="[$style.mainMenu, showMenu ? $style.shown : $style.hidden]"
    >
      <div class="container-fluid" :class="$style.contents">
        <div :class="[$style.icons]" class="pt-2 row">
          <div class="col-12">
            <RouterLink :to="{ name: 'index' }">
              <FaIcon icon="home" />
            </RouterLink>
            <a class="float-right" href="#" @click.prevent="toggleMenu">
              <FaIcon icon="chevron-circle-left" />
            </a>
          </div>
        </div>
        <MainMenu class="pt-2" full-width />
      </div>
    </div>
    <div v-touch:swipe="swipe" :class="$style.menuOpener" />
  </div>
</template>

<script>
import IsRtl from 'mixins/IsRtl';
import MainMenu from 'components/MainMenu';
import { SHOW_MENU } from './store/mutationTypes';

export default {
  components: { MainMenu },
  mixins: [IsRtl],
  props: {
    // Primary purpose is so that the swipe menu isn't enabled for the admin UI.
    enableSwipeMenu: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showMenu() {
      return this.$store.state.showMenu;
    },
  },
  watch: {
    $route() {
      this.$store.commit(SHOW_MENU, false);
    },
  },
  methods: {
    toggleMenu() {
      this.$store.dispatch('toggleMenu');
    },
    swipe(direction) {
      if (direction === 'left') {
        // Hide the menu on left swipe.
        this.$store.commit(SHOW_MENU, false);
      } else if (direction === 'right') {
        this.$store.commit(SHOW_MENU, true);
      }
    },
  },
};
</script>

<style module lang="scss">
.app {
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
}
.mainMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 25rem;
  max-width: 99%;
  min-width: 16rem;
  background-color: whitesmoke;
  bottom: 0;

  .contents {
    height: 100%;
    overflow-y: auto;
  }

  &.hidden {
    transition: left 150ms linear;
    left: -25rem;
  }
  &.shown {
    transition: left 200ms linear;
    box-shadow: 0 0 5px 5px darkgray;
  }
  .icons {
    font-size: 1.3em;
  }
}

.menuOpener {
  position: fixed;
  top: 0;
  left: 0;
  width: 10px;
  bottom: 0;
  border: none;
}
</style>
