var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ClientLayout",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("_lesson_" + _vm.lessonId)) +
                    "/" +
                    _vm._s(_vm.$t("_activity_" + _vm.activityId)) +
                    "\n  "
                )
              ]
            },
            proxy: true
          },
          !_vm.activityLoading
            ? {
                key: "toolbar",
                fn: function() {
                  return [
                    _c("PageNavigation", {
                      ref: "navigation",
                      attrs: { "qty-pages": _vm.qtyPages },
                      model: {
                        value: _vm.pageId,
                        callback: function($$v) {
                          _vm.pageId = $$v
                        },
                        expression: "pageId"
                      }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      !_vm.activityLoading
        ? [
            _c("ProgressBar", { attrs: { progress: _vm.progressPct } }),
            _vm._v(" "),
            _c("ImageGrid", {
              directives: [
                {
                  name: "touch",
                  rawName: "v-touch:swipe",
                  value: _vm.swipe,
                  expression: "swipe",
                  arg: "swipe"
                }
              ],
              key: _vm.pageId,
              staticClass: "mt-3",
              attrs: { items: _vm.itemsNormalized, "play-on-click": true }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }