var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "feedback-animation",
          rawName: "v-feedback-animation",
          value: _vm.content.status,
          expression: "content.status"
        }
      ],
      staticClass: "image-display",
      class: {
        "has-audio": _vm.hasAudio,
        selectable: _vm.alwaysSelectable,
        hover: _vm.showPlayIcon
      },
      style: _vm.autoSizeStyle,
      on: {
        click: _vm.click,
        mouseover: function($event) {
          _vm.hasHover = true
        },
        mouseout: function($event) {
          _vm.hasHover = false
        }
      }
    },
    [
      _vm.subType === "i" || _vm.disableVideo
        ? _c("img", {
            staticClass: "rounded fill-space",
            class: { border: !_vm.noBorder },
            attrs: { src: _vm.imageSrc },
            on: { ondragstart: false, ondrop: false, mousedown: _vm.mouseDown }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.subType === "v" && !_vm.disableVideo
        ? _c(
            "video",
            {
              ref: "video",
              staticClass: "rounded fill-space",
              class: { border: !_vm.noBorder },
              attrs: {
                poster: _vm.imageSrc,
                preload: "auto",
                muted: "",
                controls: _vm.videoControls && _vm.hasHover
              },
              domProps: { muted: true },
              on: { mousedown: _vm.mouseDown }
            },
            [
              _vm.videoSrc
                ? _c("source", { attrs: { src: _vm.videoSrc } })
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.videoIcon && _vm.subType === "v"
        ? _c("FaIcon", { staticClass: "rounded", attrs: { icon: "video" } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.videoControls && !_vm.displayOnly
        ? _c("InputFeedback", {
            staticClass: "rounded",
            attrs: { visible: _vm.showFeedback, success: _vm.content.status }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.displayOnly
        ? _c("PlayIcon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasAudio,
                expression: "hasAudio"
              }
            ],
            ref: "playIcon",
            staticClass: "play-icon",
            attrs: { audio: _vm.content.audio, "enable-play": _vm.enablePlay },
            on: { "play-start": _vm.playStart, "play-end": _vm.playEnd }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }