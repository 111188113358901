var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-auto", class: _vm.$style.nextActivity },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("ul", [
        _vm.nextActivity
          ? _c(
              "li",
              [
                _c(
                  "RouterLink",
                  { attrs: { to: _vm.nextActivityRoute } },
                  [
                    _c("FaIcon", {
                      class: _vm.$style.icon,
                      attrs: { icon: "caret-right" }
                    }),
                    _vm._v(" "),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("next_activity", {
                            activity: _vm.$t("_activity_" + _vm.nextActivity.id)
                          })
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.disableRepeat
          ? _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.repeatActivity($event)
                    }
                  }
                },
                [
                  _c("FaIcon", {
                    class: _vm.$style.repeat,
                    attrs: { icon: "undo" }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("repeat_activity", {
                          activity: _vm.$t("_activity_" + _vm.activityId)
                        })
                      )
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "RouterLink",
              {
                attrs: {
                  to: {
                    name: "lesson_category",
                    params: { categoryId: _vm.lessonCategoryId }
                  },
                  variant: "link"
                }
              },
              [
                _vm.category.asset.id
                  ? _c("ImageDisplay", {
                      staticClass: "d-inline-block",
                      attrs: {
                        content: { display: _vm.category.asset },
                        autosize: 23,
                        "no-video": "",
                        "display-only": ""
                      }
                    })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("_lesson_category_" + _vm.lessonCategoryId)) +
                    "\n      "
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }