var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ClientLayout",
    {
      attrs: { "auto-hide-on-load": false },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("h1", [
                _vm._v(
                  _vm._s(
                    _vm.$t("welcome_to", {
                      learn_lang_name: _vm.$t("learn_lang_name")
                    })
                  )
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm.loading ? _c("Loading", { attrs: { size: "lg" } }) : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "loginContainer", attrs: { id: "login-container" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }