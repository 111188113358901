import Vue from 'vue';

export function initializeAudio(fnPlay, fnPause) {
  if (AudioContext !== undefined) {
    const ctx = new AudioContext();
    if (ctx.state === 'running') {
      fnPlay();
    } else {
      Vue.nextTick(() => {
        // Must be in $nextTick so that the audio ref (if any) is available.
        fnPause();
        ctx.onstatechange = () => {
          fnPlay();
        };
      });
    }
  } else {
    fnPlay();
  }
}
