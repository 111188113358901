import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import store from 'store/store';
import { SETTINGS } from '../store/mutationTypes';

export default (router) => {
  Vue.use(VueAnalytics, {
    id: () =>
      new Promise((resolve) => {
        const unsubscribe = store.subscribe((mutation, state) => {
          if (mutation.type === SETTINGS) {
            if (state.data.settings.trackingId !== undefined) {
              resolve(state.data.settings.trackingId);
            } else {
              Vue.$log.warn('GA tracking code not set');
              resolve(false);
            }
            unsubscribe();
          }
        });
      }),
    router,
    debug: {
      sendHitTask: PRODUCTION,
    },
  });
};
