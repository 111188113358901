export default {
  computed: {
    isRtl() {
      return this.$store.getters.setting('langDirection') === 'rtl';
    },
    rtlStyle() {
      if (this.isRtl) {
        return {
          direction: 'rtl',
        };
      }
      return {};
    },
    isNavRtl() {
      return this.$store.getters.setting('navDirection') === 'rtl';
    },
    navLeftText() {
      if (IS_MOBILE) {
        // Disable the tooltips on mobile.
        return false;
      }
      if (this.isNavRtl) {
        return this.$t('next');
      }
      return this.$t('previous');
    },
    navRightText() {
      if (IS_MOBILE) {
        // Disable the tooltips on mobile.
        return false;
      }
      if (this.isNavRtl) {
        return this.$t('previous');
      }
      return this.$t('next');
    },
  },
};
