<template>
  <RouterLink v-if="!!to" tag="div" :class="[$style.card]" class="card flex-row" :to="to">
    <slot />
  </RouterLink>
  <div v-else :class="$style.card" class="card flex-row" @click="(e) => $emit('click', e)">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
};
</script>

<style module>
.card {
  cursor: pointer;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>
