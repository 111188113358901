var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ClientLayout",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("_lesson_" + _vm.lessonId)) +
                    "/" +
                    _vm._s(_vm.$t("_activity_" + _vm.activityId)) +
                    "\n  "
                )
              ]
            },
            proxy: true
          },
          !_vm.activityLoading
            ? {
                key: "toolbar",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        !_vm.showResults
                          ? _c("PageNavigation", {
                              staticClass: "float-left",
                              attrs: {
                                "qty-pages": _vm.qtyPages,
                                "allow-forward": _vm.allowForward
                              },
                              model: {
                                value: _vm.pageId,
                                callback: function($$v) {
                                  _vm.pageId = $$v
                                },
                                expression: "pageId"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("ProgressStats", {
                          staticClass: "float-right",
                          attrs: {
                            correct: _vm.correct.length,
                            total: _vm.completedPage + 1,
                            score: _vm.scorePct
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      !_vm.activityLoading
        ? [
            !_vm.showResults
              ? _c("ProgressBar", { attrs: { progress: _vm.progress } })
              : _vm._e(),
            _vm._v(" "),
            !_vm.showResults
              ? _c(
                  "div",
                  { staticClass: "text mt-1" },
                  [
                    _c("ContentDisplay", {
                      staticClass: "mr-1 my-1",
                      attrs: { content: _vm.currentPage.content }
                    }),
                    _vm._v(" "),
                    _vm.hasAudio
                      ? _c("PlayIcon", {
                          ref: "playIcon",
                          attrs: { "no-drop-shadow": "", audio: _vm.audio }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.showResults
              ? _c("ImageGrid", {
                  attrs: {
                    items: _vm.content,
                    "play-on-click": false,
                    selectable: true
                  },
                  on: { "image-click": _vm.imageClicked }
                })
              : _c("Results", {
                  attrs: { correct: _vm.correct, incorrect: _vm.incorrect }
                })
          ]
        : _c("Loading", {
            attrs: { size: "lg", center: "" },
            model: {
              value: _vm.activityLoading,
              callback: function($$v) {
                _vm.activityLoading = $$v
              },
              expression: "activityLoading"
            }
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }