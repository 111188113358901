export default {
  computed: {
    langData() {
      return this.$store.state.data.languages;
    },
    langLoading() {
      return this.$store.state.data.languagesLoading;
    },
  },
  methods: {
    loadLanguages(force) {
      return this.$store.dispatch('loadLanguages', force);
    },
  },
  mounted() {
    this.loadLanguages();
  },
};
