import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import ApplicationData from './ApplicationData';
import {
  ENABLE_FEEDBACK_SOUNDS,
  OPEN_LESSON_ID,
  LOCALE,
  USERNAME_ADMIN,
  USERNAME_USER,
  LOG_LEVEL,
  SHOW_MENU,
  AUTH_ENABLED,
} from './mutationTypes';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  reducer: (state) => {
    // exclude the username so that we're forced to check with the server if we're logged in
    // on every new page load.
    const exclude = ['username', 'usernameUser', 'authEnabled', 'data', 'admin', 'mobile'];
    let newState = {};
    for (let key in state) {
      if (state.hasOwnProperty(key) && exclude.indexOf(key) < 0) {
        newState[key] = state[key];
      }
    }

    return newState;
  },
});

export default new Vuex.Store({
  modules: {
    data: ApplicationData,
  },
  state: {
    enableFeedbackSounds: true,
    locale: 'en',
    openLessonId: {},
    username: undefined,
    usernameUser: undefined,
    authEnabled: true,
    logLevel: 'disabled',
    showMenu: false,
  },
  mutations: {
    [ENABLE_FEEDBACK_SOUNDS](state, payload) {
      state.enableFeedbackSounds = payload;
    },
    [LOCALE](state, payload) {
      state.locale = payload;
    },
    [OPEN_LESSON_ID](state, payload) {
      Vue.set(state.openLessonId, payload.categoryId, payload.lessonId);
    },
    [USERNAME_ADMIN](state, payload) {
      state.username = payload;
    },
    [AUTH_ENABLED](state, payload) {
      state.authEnabled = payload;
    },
    [USERNAME_USER](state, payload) {
      state.usernameUser = payload;
    },
    [LOG_LEVEL](state, payload) {
      state.logLevel = payload;
    },
    [SHOW_MENU](state, payload) {
      state.showMenu = payload;
    },
  },
  actions: {
    toggleMenu({ state, commit }) {
      commit(SHOW_MENU, !state.showMenu);
    },
  },
  getters: {
    openLessonId: (state) => (categoryId) => {
      if (state.openLessonId[categoryId] !== undefined) {
        return state.openLessonId[categoryId];
      }
      return null;
    },
    isLoggedIn: (state) => {
      return !!state.usernameUser;
    },
  },
  plugins: [vuexLocalStorage.plugin],
});
