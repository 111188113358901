<template>
  <div class="sequence-controls">
    <progress-bar :progress="progressPct" />
    <div class="controls mx-auto">
      <BButton
        v-b-tooltip.hover.top
        class="mr-3"
        :title="navLeftText"
        variant="link"
        :disabled="disableLeft"
        @click="clickLeft"
      >
        <FaIcon icon="caret-left" />
      </BButton>
      <FaIcon :icon="icon" @click="$emit('playPauseClick')" />
      <BButton
        v-b-tooltip.hover.top
        class="ml-3"
        :title="navRightText"
        variant="link"
        :disabled="disableRight"
        @click="clickRight"
      >
        <FaIcon icon="caret-right" />
      </BButton>
    </div>
  </div>
</template>

<script>
import isRtl from 'mixins/IsRtl';
import ProgressBar from '../components/ProgressBar';

export default {
  components: { ProgressBar },
  mixins: [isRtl],
  props: {
    paused: {
      type: Boolean,
    },
    isEnd: {
      type: Boolean,
      default: false,
    },
    qty: {
      type: Number,
      default: 1,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progressPct() {
      if (this.qty === 0 || this.current <= 1) {
        return 0;
      }

      if (this.isEnd) {
        return 100;
      }

      return Math.round(((this.current - 1) / (this.qty - 1)) * 100);
    },
    icon() {
      if (this.isEnd && this.paused) {
        return 'undo';
      }
      if (!this.paused) {
        return ['far', 'pause-circle'];
      }
      return ['far', 'play-circle'];
    },
    isFirst() {
      return this.current <= 1;
    },
    isLast() {
      return this.current + 1 === this.qty;
    },
    disableRight() {
      if (!this.paused) {
        return true;
      }
      if (this.isNavRtl) {
        return this.isFirst;
      }
      return this.isLast;
    },
    disableLeft() {
      if (!this.paused) {
        return true;
      }
      if (this.isNavRtl) {
        return this.isLast;
      }
      return this.isFirst;
    },
  },
  methods: {
    clickRight() {
      if (this.isNavRtl) {
        if (this.current - 2 === 0) {
          this.$root.$emit('bv::hide::tooltip');
        }
        this.$emit('retreat');
      } else {
        if (this.current + 2 >= this.qty) {
          this.$root.$emit('bv::hide::tooltip');
        }
        this.$emit('advance');
      }
    },
    clickLeft() {
      if (this.isNavRtl) {
        if (this.current + 2 >= this.qty) {
          this.$root.$emit('bv::hide::tooltip');
        }
        this.$emit('advance');
      } else {
        if (this.current - 2 === 0) {
          this.$root.$emit('bv::hide::tooltip');
        }
        this.$emit('retreat');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../styles/variables';

.sequence-controls {
  width: 100%;
}

.controls {
  color: $primary;
  cursor: pointer;
  text-align: center;

  svg {
    height: 32px;
    width: 32px;

    &:hover {
      color: darken($primary, 15%);
    }
  }

  .btn {
    padding: 0;
    border: none;
    margin-top: -15px;
  }
}
</style>
