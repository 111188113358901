export const ENABLE_FEEDBACK_SOUNDS = 'ENABLE_FEEDBACK_SOUNDS';
export const LOCALE = 'LOCALE';
export const OPEN_LESSON_ID = 'OPEN_LESSON_ID';
export const LOG_LEVEL = 'LOG_LEVEL';

export const USERNAME_ADMIN = 'USERNAME_ADMIN';
export const AUTH_ENABLED = 'AUTH_ENABLED';
export const USERNAME_USER = 'USERNAME_USER';

export const LANGUAGES = 'LANGUAGES';
export const LESSONS = 'LESSONS';
export const LESSON = 'LESSON';
export const ACTIVITIES = 'ACTIVITIES';
export const IMAGES = 'IMAGES';
export const SETTINGS = 'SETTINGS';
export const LESSON_CATEGORIES = 'LESSON_CATEGORIES';
export const CONTENT_PAGES_LIST = 'CONTENT_PAGES_LIST';

export const TRANSLATIONS_LOADING = 'TRANSLATIONS_LOADING';
export const IMAGES_LOADING = 'IMAGES_LOADING';

export const SHOW_MENU = 'SHOW_MENU';
