var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { "direction-rtl": _vm.isRtl }),
        (_obj[_vm.$style.app] = true),
        _obj),
      attrs: { id: "app" }
    },
    [
      _c("RouterView", { key: _vm.$route.path }),
      _vm._v(" "),
      _vm.enableSwipeMenu
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "touch",
                  rawName: "v-touch:swipe",
                  value: _vm.swipe,
                  expression: "swipe",
                  arg: "swipe"
                }
              ],
              class: [
                _vm.$style.mainMenu,
                _vm.showMenu ? _vm.$style.shown : _vm.$style.hidden
              ]
            },
            [
              _c(
                "div",
                { staticClass: "container-fluid", class: _vm.$style.contents },
                [
                  _c(
                    "div",
                    { staticClass: "pt-2 row", class: [_vm.$style.icons] },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "RouterLink",
                            { attrs: { to: { name: "index" } } },
                            [_c("FaIcon", { attrs: { icon: "home" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "float-right",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.toggleMenu($event)
                                }
                              }
                            },
                            [
                              _c("FaIcon", {
                                attrs: { icon: "chevron-circle-left" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("MainMenu", {
                    staticClass: "pt-2",
                    attrs: { "full-width": "" }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "touch",
            rawName: "v-touch:swipe",
            value: _vm.swipe,
            expression: "swipe",
            arg: "swipe"
          }
        ],
        class: _vm.$style.menuOpener
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }