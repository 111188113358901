import Lessons from './Lessons';
import { assetPath } from '../helpers/Media';

import { Howl } from 'howler';

export default {
  mixins: [Lessons],
  props: {
    lessonId: {
      type: Number,
      required: true,
    },
    activityId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pageId: 0,
    };
  },
  computed: {
    activityLoading() {
      return this.lessonsLoading || this.$store.getters.activityLoading(this.activityId);
    },
    activityData() {
      return this.$store.getters.activity(this.activityId);
    },
    hasActivityData() {
      return this.activityData.pages !== undefined;
    },
    qtyPages() {
      if (this.activityLoading) return 0;

      return this.activityData.pages.length;
    },
    qtyItems() {
      if (this.activityLoading) return 0;

      let qty = 0;
      for (let pageId = 0; pageId < this.qtyPages; pageId++) {
        qty += this.activityData.pages[pageId].items.length;
      }

      return qty;
    },
    currentPage() {
      if (!this.hasActivityData) {
        return {};
      }

      return this.activityData.pages[this.pageId];
    },
    isFirstPage() {
      return this.pageId <= 0;
    },
    isLastPage() {
      return this.pageId + 1 >= this.qtyPages;
    },
    itemsNormalized() {
      if (!this.currentPage.items || this.currentPage.items.length === 0) {
        return [];
      }
      let data = [];

      for (let i = 0; i < this.currentPage.items.length; i++) {
        const item = this.currentPage.items[i];
        let display = null,
          audio = null;
        for (let a = 0; a < item.assets.length; a++) {
          let asset = item.assets[a];

          switch (asset.type) {
            case 'd':
              display = asset;
              break;
            case 'a':
              audio = asset;
              break;
          }
        }

        data.push({
          id: item.id,
          display: display,
          audio: audio,
          content: item.content,
        });
      }

      return data;
    },
    lessonTitle() {
      return this.$t('_lesson_' + this.lessonId);
    },
    activityTitle() {
      return this.$t('_activity_' + this.activityId);
    },
  },
  watch: {
    pageId(newVal, oldVal) {
      if (newVal < oldVal) {
        // we paged backwards, no reason to preload.
        return;
      }
      this.preloadPage(newVal + 1);
    },
    $route: {
      handler() {
        this.loadActivity(this.activityId);
      },
      immediate: true,
    },
  },
  created() {
    // Declare here so that it isn't reactive.
    this.preloadedAssets = {};
  },
  methods: {
    async loadActivity(activityId, force) {
      if (activityId) {
        activityId = parseFloat(activityId);
      }
      await this.$store.dispatch('loadActivity', { id: activityId, force });

      // Load the current page.
      await this.preloadPage(0);
      this.$emit('activityLoaded');

      // make sure that page 2 is preloaded.
      return await this.preloadPage(1);
    },
    preloadPage(pageId) {
      if (pageId >= this.qtyPages) {
        // we're on the last page.
        return Promise.resolve(0);
      }

      return this.preloadAssets(this.activityData.pages[pageId].items);
    },
    async preloadAssets(items) {
      let stack = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        for (let a = 0; a < item.assets.length; a++) {
          const asset = item.assets[a];

          if (this.preloadAssets[asset.name] !== undefined) {
            // already preloaded, don't do it again.
            continue;
          }
          if (this.preloadedAssets[asset.id] !== undefined) {
            continue;
          }

          if (asset.type === 'd' && asset.subtype === 'i') {
            const img = new Image();
            stack.push(
              new Promise((resolve) => {
                img.addEventListener('load', resolve);
                img.addEventListener('error', resolve);
              })
            );

            img.src = assetPath(asset.name);

            this.preloadedAssets[asset.id] = img;
          } else if (asset.type === 'a') {
            stack.push(
              new Promise((resolve) => {
                this.preloadedAssets[asset.id] = new Howl({
                  src: [assetPath(asset.name)],
                  preload: true,
                  onload: resolve,
                  onloaderror: resolve,
                });
              })
            );
          }
        }
      }

      return await Promise.all(stack);
    },
  },
};
