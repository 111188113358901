<template>
  <ClientLayout>
    <template #title>
      {{ $t('_lesson_' + lessonId) }}/{{ $t('_activity_' + activityId) }}
    </template>
    <template v-if="!activityLoading" #toolbar>
      <div>
        <PageNavigation
          v-if="!showResults"
          v-model="pageId"
          class="float-left"
          :qty-pages="qtyPages"
          :allow-forward="allowForward"
        />
        <ProgressStats
          class="float-right"
          :correct="correct.length"
          :total="completedPage + 1"
          :score="scorePct"
        />
      </div>
    </template>

    <template v-if="!activityLoading">
      <ProgressBar v-if="!showResults" :progress="progress" />

      <div v-if="!showResults" class="text mt-1">
        <ContentDisplay class="mr-1 my-1" :content="currentPage.content" />
        <PlayIcon v-if="hasAudio" ref="playIcon" no-drop-shadow :audio="audio" />
      </div>
      <ImageGrid
        v-if="!showResults"
        :items="content"
        :play-on-click="false"
        :selectable="true"
        @image-click="imageClicked"
      />

      <Results v-else :correct="correct" :incorrect="incorrect" />
    </template>
    <Loading v-else v-model="activityLoading" size="lg" center />
  </ClientLayout>
</template>

<script>
import ClientLayout from '../ClientLayout';
import ImageGrid from '../components/ImageGrid';
import ContentDisplay from '../components/ContentDisplay';
import PlayIcon from '../components/PlayIcon';
import ProgressBar from '../components/ProgressBar';
import ProgressStats from '../components/ProgressStats';
import PageNavigation from '../components/PageNavigation';
import Results from '../components/Results';
import Loading from 'components/Loading';

import Lessons from 'mixins/Lessons';
import Activity from 'mixins/Activity';
import IsRtl from '../mixins/IsRtl';

import _shuffle from 'lodash/shuffle';
import _findIndex from 'lodash/findIndex';
import _delay from 'lodash/delay';

import { Correct, Incorrect } from '../helpers/FeedbackSounds';
import { saveProgress } from '../data/Result';

export default {
  components: {
    ClientLayout,
    ImageGrid,
    ContentDisplay,
    PlayIcon,
    ProgressBar,
    ProgressStats,
    PageNavigation,
    Results,
    Loading,
  },
  mixins: [Lessons, Activity, IsRtl],
  data() {
    return {
      content: [],
      correctId: 0,
      // start at less than zero so we can't navigate on the first page.
      completedPage: -1,
      correct: [],
      incorrect: [],
      showResults: false,
      takeId: Date.now(),
    };
  },
  computed: {
    audio() {
      if (this.itemsNormalized.length === 0) {
        return false;
      }
      return this.itemsNormalized[0].audio;
    },
    hasAudio() {
      if (this.itemsNormalized.length === 0) {
        return false;
      }
      return this.itemsNormalized[0].audio !== undefined;
    },
    allowForward() {
      return this.pageId <= this.completedPage;
    },
    progress() {
      if (this.completedPage < 0) {
        return 0;
      }

      return ((this.completedPage + 1) / this.qtyPages) * 100;
    },
    scorePct() {
      if (this.correct.length === 0) {
        return 0;
      }

      return Math.round((this.correct.length / (this.completedPage + 1)) * 100);
    },
  },
  watch: {
    pageId() {
      this.refreshImages();
    },
  },
  mounted() {
    this.$on('activityLoaded', () => {
      this.refreshImages();
      this.playCurrent();
    });
  },
  methods: {
    imageClicked(data) {
      const isCorrect = data.id === this.correctId,
        goNext = isCorrect,
        contentKey = _findIndex(this.content, (item) => data.id === item.id),
        correctKey = _findIndex(this.content, (item) => item.id === this.correctId),
        prevIncorrect = _findIndex(this.incorrect, (item) => item.pageId === this.pageId) > -1,
        prevCorrect = _findIndex(this.correct, (item) => item.pageId === this.pageId) > -1,
        audio = isCorrect ? Correct : Incorrect;
      this.content[contentKey].status = isCorrect;

      if (!prevCorrect && !prevIncorrect) {
        if (isCorrect) {
          this.correct.push(this.content[correctKey]);
        } else {
          this.incorrect.push(this.content[correctKey]);
        }
        saveProgress(this.takeId, this.correctId, isCorrect);
      }

      if (isCorrect && this.pageId > this.completedPage) {
        this.completedPage = this.pageId;
      }

      if (this.$store.state.enableFeedbackSounds) {
        if (audio.currentTime !== 0) {
          audio.currentTime = 0;
        }

        audio.play();
      }

      _delay(this.statusComplete, 750, contentKey, goNext);
    },
    statusComplete(contentKey, goNext) {
      this.content[contentKey].status = null;

      if (goNext) {
        if (this.pageId + 1 === this.qtyPages) {
          // This is the final page.
          this.showResults = true;
        } else {
          this.pageId++;
        }
      }
    },
    refreshImages() {
      this.correctId = this.itemsNormalized[0].id;
      this.itemsNormalized.forEach((v, k) => {
        // must be defined on the content as Vue can't detect property additions at runtime.
        // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
        v.status = null;
        v.pageId = this.pageId;
        if (k === 0) {
          v.text = this.currentPage.text;
        }
      });
      this.content = _shuffle(this.itemsNormalized);
      // next tick is required or else it will play the audio before the page is changed.
      this.$nextTick(() => {
        this.playCurrent();
      });
    },
    playCurrent() {
      let audio = this.$refs.playIcon;
      if (!audio) {
        return;
      }
      audio.play();
    },
  },
};
</script>

<style scoped>
.text {
  text-align: center;
}
.progress-stats {
  text-align: right;
}
</style>
