var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ClientLayout",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.lessonTitle) +
                    "/" +
                    _vm._s(_vm.activityTitle) +
                    " "
                )
              ]
            },
            proxy: true
          },
          !_vm.activityLoading
            ? {
                key: "toolbar",
                fn: function() {
                  return [
                    !_vm.showResults
                      ? _c("PageNavigation", {
                          staticClass: "float-left",
                          attrs: {
                            "qty-pages": _vm.qtyPages,
                            "allow-forward": _vm.allowForward
                          },
                          model: {
                            value: _vm.pageId,
                            callback: function($$v) {
                              _vm.pageId = $$v
                            },
                            expression: "pageId"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ProgressStats", {
                      staticClass: "float-right",
                      attrs: {
                        correct: _vm.correct.length,
                        total: _vm.completedPage + 1,
                        score: _vm.scorePct
                      }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      !_vm.activityLoading
        ? [
            !_vm.showResults
              ? _c("ProgressBar", { attrs: { progress: _vm.progress } })
              : _vm._e(),
            _vm._v(" "),
            !_vm.showResults
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "div",
                      [
                        _vm.hasAudio
                          ? _c("PlayIcon", {
                              ref: "playIcon",
                              attrs: {
                                audio: _vm.itemsNormalized[0].audio,
                                large: "",
                                "no-drop-shadow": ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("ContentDisplay", {
                      key: _vm.pageId,
                      attrs: {
                        content: _vm.currentPage.content,
                        "text-size": _vm.textSize
                      }
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("div", [
                      _c("table", { staticClass: "mx-auto" }, [
                        _c(
                          "tbody",
                          _vm._l(_vm.content, function(item) {
                            return _c("tr", { key: item.id }, [
                              _c(
                                "td",
                                [
                                  _c("ContentDisplay", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      content: item.content,
                                      selectable: true,
                                      success: item.status
                                    },
                                    on: {
                                      "text-click": function($event) {
                                        return _vm.textClicked(item)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showResults
              ? [
                  _c("NextActivity", {
                    attrs: {
                      "lesson-id": _vm.lessonId,
                      "activity-id": _vm.activityId
                    },
                    on: { repeat: _vm.repeat }
                  }),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("Results", {
                    attrs: { correct: _vm.correct, incorrect: _vm.incorrect }
                  })
                ]
              : _vm._e()
          ]
        : _c("Loading", { attrs: { size: "lg", center: "" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }