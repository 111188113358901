var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ClientLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("_lesson_" + _vm.lessonId)) +
                  "/" +
                  _vm._s(_vm.$t("_activity_" + _vm.activityId)) +
                  "\n  "
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      !_vm.isLoading && !_vm.showNextSteps
        ? [
            _c(
              "SizedArea",
              {
                directives: [
                  {
                    name: "touch",
                    rawName: "v-touch:swipe",
                    value: _vm.swipe,
                    expression: "swipe",
                    arg: "swipe"
                  }
                ],
                ref: "sizedArea",
                staticClass: "row",
                staticStyle: { height: "100%", position: "relative" },
                model: {
                  value: _vm.size,
                  callback: function($$v) {
                    _vm.size = $$v
                  },
                  expression: "size"
                }
              },
              [
                _c(
                  "div",
                  { class: _vm.$style.sizedWrapper },
                  [
                    _vm._l(_vm.loadedItems, function(s, sid) {
                      return _c("ImageDisplay", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.itemId === sid,
                            expression: "itemId === sid"
                          }
                        ],
                        key: sid,
                        ref: "display",
                        refInFor: true,
                        staticClass: "mx-auto p-1",
                        class: _vm.$style.imageDisplay,
                        style: _vm.imageStyle,
                        attrs: {
                          content: s,
                          "play-on-click": false,
                          "enable-play": false,
                          width: _vm.imageSize.width
                        }
                      })
                    }),
                    _vm._v(" "),
                    _c("ContentDisplay", {
                      ref: "content",
                      staticClass: "m-3",
                      class: _vm.$style.contentDisplay,
                      attrs: { content: _vm.currentSlide.content }
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.audioLoading
                  ? _c(
                      "div",
                      { class: _vm.$style.audioLoading },
                      [_c("Loading", { attrs: { size: "lg", center: "" } })],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row mb-1" },
              [
                _c("SequenceControls", {
                  attrs: {
                    paused: _vm.paused,
                    "is-end": _vm.isEnd,
                    qty: _vm.items.length + 1,
                    current: _vm.itemId + 1
                  },
                  on: {
                    playPauseClick: _vm.playPause,
                    advance: _vm.advance,
                    retreat: _vm.retreat
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("Loading", { attrs: { size: "lg", center: "" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showNextSteps
        ? _c(
            "NextActivity",
            {
              attrs: {
                "lesson-id": _vm.lessonId,
                "activity-id": _vm.activityId
              },
              on: { repeat: _vm.restart }
            },
            [_c("h1", [_vm._v(_vm._s(_vm.$t("activity_complete")))])]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }