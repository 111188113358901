var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-feedback",
      class: { show: _vm.visible, small: _vm.small }
    },
    [
      _c("span", { staticClass: "dummy-child" }),
      _vm._v(" "),
      _vm.successState
        ? _c(
            "span",
            { staticClass: "status correct" },
            [_c("FaIcon", { attrs: { icon: "check-circle" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.successState
        ? _c(
            "span",
            { staticClass: "status incorrect" },
            [_c("FaIcon", { attrs: { icon: "times-circle" } })],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }