var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sequence-controls" },
    [
      _c("progress-bar", { attrs: { progress: _vm.progressPct } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "controls mx-auto" },
        [
          _c(
            "BButton",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.top",
                  modifiers: { hover: true, top: true }
                }
              ],
              staticClass: "mr-3",
              attrs: {
                title: _vm.navLeftText,
                variant: "link",
                disabled: _vm.disableLeft
              },
              on: { click: _vm.clickLeft }
            },
            [_c("FaIcon", { attrs: { icon: "caret-left" } })],
            1
          ),
          _vm._v(" "),
          _c("FaIcon", {
            attrs: { icon: _vm.icon },
            on: {
              click: function($event) {
                return _vm.$emit("playPauseClick")
              }
            }
          }),
          _vm._v(" "),
          _c(
            "BButton",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.top",
                  modifiers: { hover: true, top: true }
                }
              ],
              staticClass: "ml-3",
              attrs: {
                title: _vm.navRightText,
                variant: "link",
                disabled: _vm.disableRight
              },
              on: { click: _vm.clickRight }
            },
            [_c("FaIcon", { attrs: { icon: "caret-right" } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }