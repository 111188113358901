var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ClientLayout",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.lessonTitle) +
                    "/" +
                    _vm._s(_vm.activityTitle) +
                    " "
                )
              ]
            },
            proxy: true
          },
          !_vm.showResults && !_vm.activityLoading
            ? {
                key: "toolbar",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c(
                          "div",
                          { staticClass: "col", class: _vm.$style.controls },
                          [
                            _c("PageNavigation", {
                              attrs: {
                                "qty-pages": _vm.qtyPages,
                                "disable-navigation": ""
                              },
                              model: {
                                value: _vm.pageId,
                                callback: function($$v) {
                                  _vm.pageId = $$v
                                },
                                expression: "pageId"
                              }
                            }),
                            _vm._v(" "),
                            _vm.queryCurrent
                              ? _c("PlayIcon", {
                                  ref: "playIcon",
                                  attrs: {
                                    audio: _vm.queryCurrent.audio,
                                    large: "",
                                    "no-drop-shadow": ""
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("ProgressStats", {
                          staticClass: "col",
                          class: _vm.$style.progressStats,
                          attrs: {
                            correct: _vm.qtyCorrect,
                            total: _vm.qtyTotal,
                            score: _vm.currentScore
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      !_vm.activityLoading
        ? [
            !_vm.showResults
              ? _c("ProgressBar", { attrs: { progress: _vm.progressPct } })
              : _vm._e(),
            _vm._v(" "),
            !_vm.showResults
              ? _c("ImageGrid", {
                  attrs: {
                    items: _vm.content,
                    "play-on-click": false,
                    selectable: true
                  },
                  on: { "image-click": _vm.imageClick }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showResults
              ? [
                  _c("NextActivity", {
                    attrs: {
                      "lesson-id": _vm.lessonId,
                      "activity-id": _vm.activityId
                    },
                    on: { repeat: _vm.repeat }
                  }),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("Results", {
                    attrs: { correct: _vm.correct, incorrect: _vm.incorrect }
                  })
                ]
              : _vm._e()
          ]
        : _c("Loading", { attrs: { size: "lg", center: "" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }