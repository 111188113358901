var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "play-controls rounded-circle",
      class: {
        "drop-shadow": !_vm.noDropShadow,
        large: _vm.large,
        disabled: !_vm.enablePlay && !_vm.playing,
        error: _vm.error
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.play($event)
        }
      }
    },
    [_c("FaIcon", { attrs: { icon: _vm.icon } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }