<template>
  <ClientLayout>
    <template #title>
      {{ $t('_lesson_' + lessonId) }}/{{ $t('_activity_' + activityId) }}
    </template>

    <template v-if="!isLoading && !showNextSteps">
      <SizedArea
        ref="sizedArea"
        v-model="size"
        v-touch:swipe="swipe"
        style="height: 100%; position: relative;"
        class="row"
      >
        <div :class="$style.sizedWrapper">
          <ImageDisplay
            v-for="(s, sid) in loadedItems"
            v-show="itemId === sid"
            :key="sid"
            ref="display"
            :content="s"
            :play-on-click="false"
            :enable-play="false"
            :width="imageSize.width"
            :style="imageStyle"
            class="mx-auto p-1"
            :class="$style.imageDisplay"
          />
          <ContentDisplay
            ref="content"
            :content="currentSlide.content"
            :class="$style.contentDisplay"
            class="m-3"
          />
        </div>
        <div v-if="audioLoading" :class="$style.audioLoading">
          <Loading size="lg" center />
        </div>
      </SizedArea>
      <div class="row mb-1">
        <SequenceControls
          :paused="paused"
          :is-end="isEnd"
          :qty="items.length + 1"
          :current="itemId + 1"
          @playPauseClick="playPause"
          @advance="advance"
          @retreat="retreat"
        />
      </div>
    </template>
    <Loading v-if="loading" size="lg" center />
    <NextActivity
      v-if="showNextSteps"
      :lesson-id="lessonId"
      :activity-id="activityId"
      @repeat="restart"
    >
      <h1>{{ $t('activity_complete') }}</h1>
    </NextActivity>
  </ClientLayout>
</template>

<script>
import ClientLayout from '../ClientLayout';

import Activity from 'mixins/Activity';
import IsRtl from 'mixins/IsRtl';
import SizedArea from '../components/SizedArea';
import ImageDisplay from '../components/ImageDisplay';
import ContentDisplay from '../components/ContentDisplay';
import SequenceControls from '../components/SequenceControls';
import Loading from '../components/Loading';
import NextActivity from '../components/NextActivity';

import { Howl } from 'howler';
import { audioPath } from '../helpers/Media';
import { initializeAudio } from '../lib/initalizeAudio';
import { calculateSize, imageStyle } from '../lib/image';
import { noSleep } from '../lib/NoSleep';

export default {
  components: {
    NextActivity,
    Loading,
    SequenceControls,
    ContentDisplay,
    ImageDisplay,
    SizedArea,
    ClientLayout,
  },
  mixins: [Activity, IsRtl],
  data() {
    return {
      loading: true,
      audioLoading: false,
      showNextSteps: false,
      itemId: 0,
      items: [],

      timerId: null,

      playKey: 0,
      playKeyCurrent: 1,

      imageSize: {},
      size: {
        height: null,
        width: null,
        ratio: null,
      },
    };
  },
  computed: {
    isLoading() {
      return this.activityLoading || this.loading;
    },
    activityData() {
      return this.$store.getters.activity(this.activityId);
    },
    loadedItems() {
      const preloadQty = 5;
      let items = [];
      for (let i = 0; i < this.itemId + preloadQty && i < this.items.length; i++) {
        items.push(this.items[i]);
      }

      return items;
    },
    paused() {
      return this.playKey !== this.playKeyCurrent;
    },
    isEnd() {
      return this.itemId + 1 >= this.items.length;
    },
    currentAudio() {
      if (this.loading) {
        return undefined;
      }
      return this.audioFiles[this.currentSlide.pageId];
    },
    progress() {
      let qty = 0;

      for (let i = 0; i < this.pageId; i++) {
        qty += this.activityData.pages[i].items.length;
      }

      qty += this.itemId;
      return qty;
    },
    currentSlide() {
      return this.items[this.itemId];
    },
    imageStyle() {
      return imageStyle(this.imageSize);
    },
  },
  watch: {
    itemId: {
      handler() {
        this.$nextTick(() => {
          this.calculateImageSize();
          if (!this.paused) {
            this.$refs.display[this.itemId].playStart();
          }
        });
        if (this.paused) {
          this.currentAudio.seek(this.currentSlide.timing);
        }
      },
      immediate: false,
    },
    size() {
      this.calculateImageSize();
    },
    paused: {
      handler(newVal) {
        if (newVal === false) {
          this.$log.debug('noSleep enabled');
          noSleep.enable();
        } else {
          this.$log.debug('noSleep disabled');
          noSleep.disable();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$on('activityLoaded', async () => {
      let items = [];

      for (let pageId = 0; pageId < this.activityData.pages.length; pageId++) {
        const page = this.activityData.pages[pageId],
          asset = page.assets[0];
        const path = await audioPath(asset.name);

        this.audioFiles[pageId] = new Howl({
          src: [path],
          preload: true,
          onend: () => {
            this.fileEnd(pageId);
          },
        });

        for (let itemId = 0; itemId < page.items.length; itemId++) {
          const item = page.items[itemId],
            image = item.assets[0];
          items.push({
            timing: parseFloat(item.config.timing),
            pageId: pageId,
            display: image,
            content: item.content,
          });
        }
      }

      this.items = items;
      const file = this.audioFiles[0];

      if (items[0].timing > 0) {
        // The first image starts the audio past the beginning.
        file.seek(items[0].timing);
      }

      if (file.state() !== 'loaded') {
        this.audioFiles[0].on('load', () => {
          this.loading = false;
          initializeAudio(this.play, this.pause);
        });
      } else {
        this.loading = false;
        initializeAudio(this.play, this.pause);
      }

      this.$nextTick(() => this.calculateImageSize());
    });
  },
  beforeDestroy() {
    for (let i = 0; i < this.audioFiles.length; i++) {
      // Stop all files.
      this.audioFiles[i].stop();
    }
    noSleep.disable();
  },
  created() {
    this.audioFiles = [];
  },
  methods: {
    playPause() {
      if (this.paused && this.isEnd) {
        this.restart();
        return;
      }
      if (this.paused) {
        this.play();
      } else {
        this.pause();
      }
    },
    pause() {
      this.currentAudio.pause();
      this.$refs.display[this.itemId].playEnd();
      this.playKey++;
      this.$log.debug('pause');
      this.setTimeout();
    },
    play() {
      this.playKey = this.playKeyCurrent;

      const doPlay = () => {
        this.audioLoading = false;
        // Make sure user didn't pause playback while waiting.
        if (!this.paused) {
          this.setTimeout();
          this.$refs.display[this.itemId].playStart();
          this.currentAudio.play();
          this.$log.debug('play');
        }
      };

      if (this.currentAudio.state() !== 'loaded') {
        this.audioLoading = true;
        this.currentAudio.on('load', doPlay);
      } else {
        doPlay();
      }
    },
    fileEnd(pageId) {
      this.$log.debug('end of file');
      this.audioFiles[pageId].stop();
      if (!this.paused && !this.isEnd) {
        this.itemId++;
        // Seek the new audio file to it's starting position.
        this.currentAudio.seek(this.currentSlide.timing);
        this.play();
      } else if (this.isEnd) {
        // Put us into the paused state.
        this.pause();
        this.showNextSteps = true;
      }
    },
    setTimeout() {
      if (this.timerId !== null) {
        clearInterval(this.timerId);
      }
      if (this.paused) {
        return;
      }

      const isLastSlide = this.itemId + 1 >= this.items.length;

      if (isLastSlide) {
      } else {
        const nextSlide = this.items[this.itemId + 1];

        if (nextSlide.pageId !== this.currentSlide.pageId) {
          // The next slide is a different audio file, we'll wait for the current file to end.
          return;
        }
        let timeout = nextSlide.timing - this.currentAudio.seek();

        this.timerId = setInterval(() => {
          this.itemId++;
          this.setTimeout();
          this.$log.debug('timeout');
        }, timeout * 1000);
      }
    },
    restart() {
      this.itemId = 0;
      this.showNextSteps = false;
      // Must be in next tick for the NextActivity redo functionality to work.
      this.$nextTick(() => this.play());
    },
    advance() {
      if (this.isEnd) {
        return;
      }
      this.itemId++;
    },
    retreat() {
      if (this.itemId === 0) {
        return;
      }
      this.itemId--;
    },
    swipe(direction) {
      if (!this.paused) {
        return;
      }

      const swapper = { left: 'right', right: 'left' };

      if (!swapper[direction]) {
        // up and down swipe directions are ignored.
        return;
      }

      if (this.isNavRtl) {
        direction = swapper[direction];
      }

      if (direction === 'right') {
        this.retreat();
      } else {
        this.advance();
      }
    },
    calculateImageSize() {
      // This manual calculation is neccissary because the content isn't yet rendered when
      // the image is shown, so it doesn't work in a computed property. To allow the content
      // to render, we call this function inside $nextTick so the content is fully rendered
      // and can affect the size of the image.
      this.imageSize = calculateSize(
        this.size,
        this.currentSlide.display.metadata,
        this.$refs.content
      );
    },
  },
};
</script>

<style module>
.sizedWrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.contentDisplay {
  /* must be important to override the <ContentDisplay> component default */
  position: absolute !important;
  left: 0;
  right: 0;
}
.imageDisplay {
  width: 100%;
  height: 100%;
}
.audioLoading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  opacity: 0.75;
}
</style>
