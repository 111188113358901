import translationsReady from 'lib/TranslationsReady';

import Index from 'pages/Index';
import CategoryLessons from 'pages/CategoryLessons';

import LessonDiscovery from 'pages/Discovery';
import LessonQuiz from 'pages/Quiz';
import LessonReadSelectImage from 'pages/ReadSelectImage';
import LessonImageSelectText from 'pages/ImageSelectText';
import LessonMatchImageText from 'pages/MatchImageText';
import LessonSequence from 'pages/Sequence';
import LessonSequenceAudio from 'pages/SequenceAudio';
import LessonMultipleChoiceText from 'pages/MultipleChoiceText';
import Loading from 'components/Loading';
import Settings from 'pages/Settings';
import OfflineMgt from 'pages/mobile/OfflineMgt';
import Login from 'pages/LogIn';

const LessonText = () => ({
  component: import(/* webpackChunkName: "lazy" */ '../pages/Text'),
  loading: Loading,
});
const PhotoCredits = () => ({
  component: import(/* webpackChunkName: "lazy" */ '../pages/PhotoCredits'),
  loading: Loading,
});
const ContentPage = () => ({
  component: import(/* webpackChunkName: "lazy" */ '../pages/ContentPage'),
  loading: Loading,
});
const ContactPage = () => ({
  component: import(/* webpackChunkName: "lazy" */ '../pages/Contact'),
  loading: Loading,
});

const ActivityTitle = (to, t) => {
  return t('page_title.activity', [
    t('_lesson_' + to.params.lessonId),
    t('_activity_' + to.params.activityId),
  ]);
};

function propsCaster(route) {
  const props = route.params;

  for (const k in props) {
    props[k] = Number(props[k]);
  }

  return props;
}

let routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {
      title: () => translationsReady((t) => t('page_title.page', [t('page_title.home')])),
    },
  },
  {
    path: '/:categoryId(\\d+)',
    name: 'lesson_category',
    component: CategoryLessons,
    props: propsCaster,
    meta: {
      title: (to) =>
        translationsReady((t) =>
          t('page_title.page', [t('_lesson_category_' + to.params.categoryId)])
        ),
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      title: () => translationsReady((t) => t('page_title.page', [t('settings.title')])),
    },
  },
  {
    path: '/lesson/:lessonId/discovery/:activityId',
    name: 'lesson_discovery',
    component: LessonDiscovery,
    props: propsCaster,
    meta: {
      title: (to) => translationsReady((t) => ActivityTitle(to, t)),
    },
  },
  {
    path: '/lesson/:lessonId/quiz/:activityId',
    name: 'lesson_quiz',
    component: LessonQuiz,
    props: propsCaster,
    meta: {
      title: (to) => translationsReady((t) => ActivityTitle(to, t)),
    },
  },
  {
    path: '/lesson/:lessonId/read_select_image/:activityId',
    name: 'lesson_read_select_image',
    component: LessonReadSelectImage,
    props: propsCaster,
    meta: {
      title: (to) => translationsReady((t) => ActivityTitle(to, t)),
    },
  },
  {
    path: '/lesson/:lessonId/image_select_text/:activityId',
    name: 'lesson_image_select_text',
    component: LessonImageSelectText,
    props: propsCaster,
    meta: {
      title: (to) => translationsReady((t) => ActivityTitle(to, t)),
    },
  },
  {
    path: '/lesson/:lessonId/match_image_text/:activityId',
    name: 'lesson_match_image_text',
    component: LessonMatchImageText,
    props: propsCaster,
    meta: {
      title: (to) => translationsReady((t) => ActivityTitle(to, t)),
    },
  },
  {
    path: '/lesson/:lessonId/text/:activityId',
    name: 'lesson_text',
    component: LessonText,
    props: propsCaster,
    meta: {
      title: (to) => translationsReady((t) => ActivityTitle(to, t)),
    },
  },
  {
    path: '/lesson/:lessonId/sequence/:activityId',
    name: 'lesson_sequence',
    component: LessonSequence,
    props: propsCaster,
    meta: {
      title: (to) => translationsReady((t) => ActivityTitle(to, t)),
    },
  },
  {
    path: '/lesson/:lessonId/audio_sequence/:activityId',
    name: 'lesson_audio_sequence',
    component: LessonSequenceAudio,
    props: propsCaster,
    meta: {
      title: (to) => translationsReady((t) => ActivityTitle(to, t)),
    },
  },
  {
    path: '/lesson/:lessonId/multiple_choice_text/:activityId',
    name: 'lesson_multiple_choice_text',
    component: LessonMultipleChoiceText,
    props: propsCaster,
    meta: {
      title: (to) => translationsReady((t) => ActivityTitle(to, t)),
    },
  },
  {
    path: '/photo_credits',
    name: 'photo_credits',
    component: PhotoCredits,
    meta: {
      title: () => translationsReady((t) => t('page_title.page', [t('photo_credits')])),
    },
  },
  {
    path: '/page/:id',
    name: 'content_page',
    component: ContentPage,
    // The ID isn't a number, dont' use the propsCaster.
    props: true,
    meta: {
      title: (to) =>
        translationsReady((t) => t('page_title.page', [t('_content_page_title_' + to.params.id)])),
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
    meta: {
      title: () => translationsReady((t) => t('page_title.contact')),
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: () => translationsReady((t) => t('page_title.login')),
    },
  },
];

if (IS_MOBILE) {
  routes.push({
    path: '/offline_mgt',
    name: 'offline_mgt',
    component: OfflineMgt,
    meta: {
      title: () => translationsReady((t) => t('offline_mgt')),
    },
  });
}

export default routes;
