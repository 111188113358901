var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.to
    ? _c(
        "RouterLink",
        {
          staticClass: "card flex-row",
          class: [_vm.$style.card],
          attrs: { tag: "div", to: _vm.to }
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "div",
        {
          staticClass: "card flex-row",
          class: _vm.$style.card,
          on: {
            click: function(e) {
              return _vm.$emit("click", e)
            }
          }
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }