import Base from './BaseData';

import { listDisplay } from './mobile/assets';

export async function load(type, all, withUsage) {
  if (!type) {
    type = 'd';
  }

  let url = `asset?type=${type}`;

  if (withUsage === true) {
    url += '&u=1';
  }

  if (all !== true && withUsage !== true) {
    url = '/asset/credits';
  }

  if (IS_MOBILE) {
    return await listDisplay();
  }
  return Base(url);
}
