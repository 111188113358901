<template>
  <div v-if="qtyPages > 1" class="page-navigation">
    <BButton
      v-if="qtyPages > 1 && !disableNavigation"
      id="nav-left"
      v-b-tooltip.hover="navLeftText"
      :disabled="(isNavRtl ? isLastPage || !allowForward : isFirstPage)"
      @click.prevent="clickLeft"
    >
      <FaIcon icon="caret-left" />
    </BButton>

    <span>{{ $t('page_x_of_y', [currentPage, qtyPages]) }}</span>

    <BButton
      v-if="qtyPages > 1 && !disableNavigation"
      id="nav-right"
      v-b-tooltip.hover="navRightText"
      :disabled="(isNavRtl ? isFirstPage : isLastPage || !allowForward)"
      @click.prevent="clickRight"
    >
      <FaIcon icon="caret-right" />
    </BButton>
  </div>
</template>

<script>
import IsRtl from 'mixins/IsRtl';

export default {
  mixins: [IsRtl],
  props: {
    qtyPages: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    /**
     * Disabled the navigation capabilities so it is just a page x of y display.
     */
    disableNavigation: {
      type: Boolean,
      default: false,
    },
    allowForward: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.qtyPages;
    },
    currentPage() {
      return this.value + 1;
    },
  },
  methods: {
    clickLeft() {
      if (this.isNavRtl) {
        if (!this.isLastPage) {
          this.goNext();
        }
      } else {
        if (!this.isFirstPage) {
          this.goPrevious();
        }
      }
    },
    clickRight() {
      if (this.isNavRtl) {
        if (!this.isFirstPage) {
          this.goPrevious();
        }
      } else {
        if (!this.isLastPage) {
          this.goNext();
        }
      }
    },
    goNext() {
      // Add 1 because value is base zero.
      // Add another to get to check the next page.
      if (this.value + 2 === this.qtyPages) {
        this.$root.$emit('bv::hide::tooltip');
      }
      this.$emit('input', this.value + 1);
    },
    goPrevious() {
      // Check if this is the second page.
      if (this.value === 1) {
        this.$root.$emit('bv::hide::tooltip');
      }
      this.$emit('input', this.value - 1);
    },
  },
};
</script>

<style scoped lang="scss">
.page-navigation {
  text-align: center;
  white-space: nowrap;

  div {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
