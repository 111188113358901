<template>
  <ClientLayout>
    <template #title> {{ lessonTitle }}/{{ activityTitle }} </template>

    <template v-if="!showResults && !activityLoading" #toolbar>
      <div class="row">
        <div class="col" :class="$style.controls">
          <PageNavigation v-model="pageId" :qty-pages="qtyPages" disable-navigation />
          <PlayIcon
            v-if="queryCurrent"
            ref="playIcon"
            :audio="queryCurrent.audio"
            large
            no-drop-shadow
          />
        </div>
        <ProgressStats
          class="col"
          :class="$style.progressStats"
          :correct="qtyCorrect"
          :total="qtyTotal"
          :score="currentScore"
        />
      </div>
    </template>

    <template v-if="!activityLoading">
      <ProgressBar v-if="!showResults" :progress="progressPct" />

      <ImageGrid
        v-if="!showResults"
        :items="content"
        :play-on-click="false"
        :selectable="true"
        @image-click="imageClick"
      />
      <template v-if="showResults">
        <NextActivity :lesson-id="lessonId" :activity-id="activityId" @repeat="repeat" />
        <hr />
        <Results :correct="correct" :incorrect="incorrect" />
      </template>
    </template>
    <Loading v-else size="lg" center />
  </ClientLayout>
</template>

<script>
import { Correct, Incorrect } from '../helpers/FeedbackSounds';

import Activity from 'mixins/Activity';

import PageNavigation from '../components/PageNavigation';
import Results from '../components/Results';
import NextActivity from '../components/NextActivity';
import ClientLayout from '../ClientLayout';
import ImageGrid from '../components/ImageGrid';
import PlayIcon from '../components/PlayIcon';
import ProgressBar from '../components/ProgressBar';
import ProgressStats from '../components/ProgressStats';
import Loading from '../components/Loading';

import _shuffle from 'lodash/shuffle';
import _random from 'lodash/random';
import _delay from 'lodash/delay';
import _clone from 'lodash/clone';
import _findIndex from 'lodash/findIndex';
import _take from 'lodash/take';
import _concat from 'lodash/concat';
import { saveProgress } from '../data/Result';

export default {
  components: {
    NextActivity,
    Loading,
    ClientLayout,
    ImageGrid,
    PlayIcon,
    ProgressBar,
    ProgressStats,
    PageNavigation,
    Results,
  },
  mixins: [Activity],
  data() {
    return {
      content: [],
      correct: [],
      incorrect: [],
      pageCorrect: [],
      pageIncorrect: [],
      queryCurrent: null,
      queryPending: [],
      qtyComplete: 0,
      // We don't allow the user to click on an incorrect answer while the correct one is
      // still "bouncing". When this happened, the query would get marked as both correct
      // and incorrect. We do allow the user to select the correct answer before the
      // incorrect one is finished with it's animation.
      allowIncorrect: true,
      showResults: false,
      takeId: Date.now(),
    };
  },
  computed: {
    qtyCorrect() {
      return this.correct.length;
    },
    qtyIncorrect() {
      return this.incorrect.length;
    },
    qtyTotal() {
      const total = this.qtyCorrect + this.qtyIncorrect;
      if (total > 0) {
        return total;
      }
      return 0;
    },
    currentScore() {
      if (this.qtyCorrect === 0) {
        return 0;
      }
      return Math.round((this.qtyCorrect / this.qtyTotal) * 100);
    },
    progressPct() {
      if (this.qtyComplete === 0) {
        return 0;
      }
      return Math.round((this.qtyComplete / this.qtyItems) * 100);
    },
  },
  watch: {
    pageId() {
      this.pageCorrect = [];
      this.pageIncorrect = [];
    },
  },
  mounted() {
    this.$on('activityLoaded', () => {
      this.start();
    });
  },
  methods: {
    start() {
      this.refreshImages();
      this.$nextTick(() => {
        this.playCurrent();
      });
    },
    repeat() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.start();
    },
    imageClick(data) {
      const clickedId = data.id,
        correctId = this.queryCurrent.id,
        isCorrect = clickedId === correctId,
        previousIncorrect = _findIndex(this.pageIncorrect, (item) => correctId === item.id) > -1,
        contentId = _findIndex(this.content, (item) => clickedId === item.id);
      let audio = Correct;

      if (!isCorrect && !this.allowIncorrect) {
        return;
      }

      this.content[contentId].status = isCorrect;

      if (isCorrect) {
        if (!previousIncorrect) {
          this.correct.push(this.queryCurrent);
          this.pageCorrect.push(this.queryCurrent);
          saveProgress(this.takeId, this.queryCurrent.id, isCorrect);
        }
        this.qtyComplete++;
      } else {
        if (!previousIncorrect) {
          this.incorrect.push(this.queryCurrent);
          this.pageIncorrect.push(this.queryCurrent);
          saveProgress(this.takeId, this.queryCurrent.id, isCorrect);
        }
        audio = Incorrect;
      }

      if (this.$store.state.enableFeedbackSounds) {
        if (audio.currentTime !== 0) {
          audio.currentTime = 0;
        }
        audio.play();
      }

      this.allowIncorrect = false;
      _delay(this.statusComplete, 750, clickedId, isCorrect);
    },
    statusComplete(queryClickedId, goNext) {
      this.content[_findIndex(this.content, (item) => item.id === queryClickedId)].status = null;
      this.allowIncorrect = true;

      if (goNext) {
        // Remove the item from the pending play list.
        this.queryPending.splice(
          _findIndex(this.queryPending, (item) => item.id === queryClickedId),
          1
        );
        // User completed the final query, proceed to the next page.
        if (this.queryPending.length === 0) {
          if (this.pageId + 1 === this.qtyPages) {
            this.showResults = true;
          } else {
            this.pageId++;
          }
        }
        if (!this.showResults) {
          this.refreshImages();
          this.$nextTick(function () {
            // We can only play the correct audio once the DOM is updated.
            this.playCurrent();
          });
        }
      }
    },
    refreshImages() {
      if (this.queryPending.length === 0) {
        // first view.
        let items = _clone(this.itemsNormalized);
        items.forEach((v) => {
          // must be defined on the content as Vue can't detect property additions at runtime.
          // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
          v.status = null;
        });
        this.queryPending = items;
      }
      const max =
          this.activityData.config.maxShown !== undefined
            ? this.activityData.config.maxShown
            : this.itemsNormalized.length,
        pendingActive = _take(this.queryPending, max),
        min =
          this.activityData.config.minShown !== undefined
            ? this.activityData.config.minShown
            : this.itemsNormalized.length,
        fillIn = pendingActive.length >= min ? 0 : min - pendingActive.length;
      let content = pendingActive;

      if (fillIn > 0) {
        // Fill in with a random selection of previous correct and incorrect items from the current page.
        content = _concat(
          content,
          _take(_shuffle(_concat(this.pageCorrect, this.pageIncorrect)), fillIn)
        );
      }

      this.content = _shuffle(content);

      if (this.currentPage.config.random === true) {
        this.queryCurrent = pendingActive[_random(0, pendingActive.length - 1)];
      } else {
        // Don't randomize the quiz!
        this.queryCurrent = pendingActive[0];
      }
    },
    playCurrent() {
      let audio = this.$refs.playIcon;
      if (!audio) {
        return;
      }

      audio.play();
    },
  },
};
</script>

<style module lang="scss">
.progressStats {
  text-align: right;
}
.controls {
  text-align: center;

  div {
    display: inline-block !important;
    vertical-align: middle;
  }
}
</style>
