<template>
  <ClientLayout>
    <template #title> {{ lessonTitle }}/{{ activityTitle }} </template>

    <template v-if="!activityLoading" #toolbar>
      <PageNavigation
        v-if="!showResults"
        v-model="pageId"
        class="float-left"
        :qty-pages="qtyPages"
        :allow-forward="allowForward"
      />
      <ProgressStats
        class="float-right"
        :correct="correct.length"
        :total="completedPage + 1"
        :score="scorePct"
      />
    </template>

    <template v-if="!activityLoading">
      <ProgressBar v-if="!showResults" :progress="progress" />

      <div v-if="!showResults" class="text-center">
        <div>
          <PlayIcon
            v-if="hasAudio"
            ref="playIcon"
            :audio="itemsNormalized[0].audio"
            large
            no-drop-shadow
          />
        </div>
        <!-- If the :key is ommited, for some reason the font size won't update. -->
        <ContentDisplay :key="pageId" :content="currentPage.content" :text-size="textSize" />
        <hr />
        <div>
          <table class="mx-auto">
            <tbody>
              <tr v-for="item in content" :key="item.id">
                <td>
                  <ContentDisplay
                    :content="item.content"
                    :selectable="true"
                    :success="item.status"
                    style="width: 100%;"
                    @text-click="textClicked(item)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-if="showResults">
        <NextActivity :lesson-id="lessonId" :activity-id="activityId" @repeat="repeat" />
        <hr />
        <Results :correct="correct" :incorrect="incorrect" />
      </template>
    </template>
    <Loading v-else size="lg" center />
  </ClientLayout>
</template>

<script>
import ClientLayout from '../ClientLayout';

import Lessons from '../mixins/Lessons';
import Activity from '../mixins/Activity';
import IsRtl from '../mixins/IsRtl';
import Loading from '../components/Loading';
import ProgressBar from '../components/ProgressBar';
import PageNavigation from '../components/PageNavigation';
import ProgressStats from '../components/ProgressStats';
import ContentDisplay from '../components/ContentDisplay';
import PlayIcon from '../components/PlayIcon';
import Results from '../components/Results';
import NextActivity from '../components/NextActivity';

import _shuffle from 'lodash/shuffle';
import _findIndex from 'lodash/findIndex';
import _delay from 'lodash/delay';

import { Correct, Incorrect } from '../helpers/FeedbackSounds';
import { saveProgress } from '../data/Result';

export default {
  components: {
    NextActivity,
    Results,
    ContentDisplay,
    ProgressStats,
    PageNavigation,
    ProgressBar,
    Loading,
    ClientLayout,
    PlayIcon,
  },
  mixins: [Lessons, Activity, IsRtl],
  data() {
    return {
      content: [],
      correctId: 0,
      completedPage: -1,
      correct: [],
      incorrect: [],
      showResults: false,
      isPlaying: false,
      takeId: Date.now(),
    };
  },
  computed: {
    allowForward() {
      return this.pageId <= this.completedPage;
    },
    progress() {
      if (this.completedPage < 0) {
        return 0;
      }

      return ((this.completedPage + 1) / this.qtyPages) * 100;
    },
    scorePct() {
      if (this.correct.length === 0) {
        return 0;
      }

      return Math.round((this.correct.length / (this.completedPage + 1)) * 100);
    },
    textSize() {
      let textSize = this.currentPage.config.text_size;
      if (textSize !== undefined) {
        textSize = parseFloat(textSize);
        if (textSize > 0) {
          return textSize;
        }
      }
      textSize = this.activityData.config.text_size;
      if (textSize !== undefined) {
        textSize = parseFloat(textSize);
        if (textSize > 0) {
          return textSize;
        }
      }
      return 0;
    },
    hasAudio() {
      return this.itemsNormalized[0].audio !== null;
    },
  },
  watch: {
    pageId() {
      this.refreshContent();
    },
  },
  mounted() {
    this.$on('activityLoaded', () => {
      this.start();
    });
  },
  methods: {
    start() {
      if (this.activityData.config.randomize_pages === true) {
        this.activityData.pages = _shuffle(this.activityData.pages);
      }
      this.refreshContent();
      this.playCurrent();
    },
    repeat() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.start();
    },
    refreshContent() {
      this.correctId = this.itemsNormalized[0].id;
      this.itemsNormalized.forEach((v) => {
        this.$set(v, 'status', null);
        this.$set(v, 'pageId', this.pageId);
      });
      this.content = _shuffle(this.itemsNormalized);
    },
    textClicked(itemClicked) {
      const isCorrect = itemClicked.id === this.correctId,
        clickedKey = _findIndex(this.content, (item) => item.id === itemClicked.id),
        prevIncorrect = _findIndex(this.incorrect, (item) => item.pageId === this.pageId) > -1,
        prevCorrect = _findIndex(this.correct, (item) => item.pageId === this.pageId) > -1,
        feedbackAudio = isCorrect ? Correct : Incorrect;
      this.content[clickedKey].status = isCorrect;

      if (!prevIncorrect && !prevCorrect) {
        const currentItem = {
          content: this.currentPage.content,
          pageId: this.pageId,
        };
        if (isCorrect) {
          this.correct.push(currentItem);
        } else {
          this.incorrect.push(currentItem);
        }
        saveProgress(this.takeId, this.correctId, isCorrect);
      }

      if (isCorrect && this.pageId > this.completedPage) {
        this.completedPage = this.pageId;
      }

      if (this.$store.state.enableFeedbackSounds) {
        if (feedbackAudio.currentTime !== 0) {
          feedbackAudio.currentTime = 0;
        }

        feedbackAudio.play();
      }

      _delay(this.statusComplete, 750, clickedKey, isCorrect);
    },
    statusComplete(contentKey, goNext) {
      this.content[contentKey].status = null;

      if (goNext) {
        if (this.pageId + 1 === this.qtyPages) {
          // this is the final page
          this.showResults = true;
        } else {
          this.pageId++;
        }

        if (!this.showResults) {
          this.playCurrent();
        }
      }
    },
    playCurrent() {
      if (!this.hasAudio) {
        return;
      }

      this.$nextTick(() => {
        const audio = this.$refs.playIcon;

        if (!audio) {
          return;
        }
        audio.play();
      });
    },
  },
};
</script>
