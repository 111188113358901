import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from 'i18n';
import store from 'store/store';

Vue.use(VueRouter);

import routes from './routes';

const router = new VueRouter({
  mode: 'hash',
  routes,
});

export default router;

import _isFunction from 'lodash/isFunction';

import { isOffline } from '../data/mobile/activity';
import { confirm, alert } from '../lib/mobile/dialog';

if (IS_MOBILE) {
  router.beforeEach(async (to, from, next) => {
    if (to.name.indexOf('lesson_') === 0 && to.params.activityId) {
      const availableOffline = await isOffline(to.params.activityId);

      if (availableOffline) {
        next();
      } else {
        if (store.state.mobile.online !== true) {
          await alert(
            i18n.t('mobile.app_offline'),
            i18n.t('mobile.activity'),
            i18n.t('mobile.cancel')
          );
          next(false);
        } else {
          const action = await confirm(
            i18n.t('mobile.activity_download_prompt'),
            i18n.t('mobile.activity'),
            [i18n.t('mobile.download'), i18n.t('mobile.cancel')]
          );
          if (action === 1) {
            // Download
            next();
          } else {
            // Cancel
            next(false);
          }
        }
      }
    } else {
      next();
    }
  });
}

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta.title);

  if (nearestWithTitle) {
    const title = nearestWithTitle.meta.title;
    if (_isFunction(title)) {
      const res = title(to);
      if (res.then !== undefined) {
        res.then((res) => {
          document.title = res;
        });
      } else {
        document.title = res;
      }
    } else {
      document.title = title;
    }
  }
  return next();
});
