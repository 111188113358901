import axios from 'axios';
import store from 'store/store';
import { USERNAME_ADMIN } from '../store/mutationTypes';
import { getAuth } from '../config/Firebase';

let baseUrl = '/api';

if (!PRODUCTION) {
  baseUrl = `http://${window.location.hostname}:3001/api`;
}

if (IS_MOBILE) {
  baseUrl = `${MOBILE_DOMAIN}/api`;
}

export const BaseUrl = baseUrl;

const api = axios.create({
  baseURL: baseUrl,
  // So that cookies are included.
  withCredentials: true,
  headers: {
    post: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    get: {
      Accept: 'application/json',
    },
  },
});

api.interceptors.request.use(async (config) => {
  if (config.url.indexOf('config') >= 0 || !store.state.authEnabled) {
    // The config request never needs auth info.
    // In fact, we'd have a deadlock if we waited for auth because we need the config before we can authorize!
    return config;
  }
  const auth = await getAuth();
  if (auth === false) {
    return config;
  }

  if (!auth.currentUser) {
    console.log('No currentUser');
    // We aren't logged in.
    return config;
  }

  const token = await auth.currentUser.getIdToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      // 403 forbidden
      store.commit(USERNAME_ADMIN, undefined);
    }
    return Promise.reject(error);
  }
);

export default api;
