import _find from 'lodash/find';

export default {
  computed: {
    lessonsLoading() {
      return this.$store.state.data.lessonsLoading;
    },
    lessonData() {
      return this.$store.state.data.lessons;
    },
    lessonEntity() {
      if (!this.lessonId || this.$store.getters.appLoading) {
        return null;
      }
      return _find(this.lessonData, (l) => l.id === this.lessonId);
    },
    lessonCategoryId() {
      if (!this.lessonEntity) {
        return null;
      }
      return this.lessonEntity.category_id;
    },
  },
  methods: {
    loadLessons(force) {
      return this.$store.dispatch('loadLessons', force).then(() => {
        this.$emit('lessonsLoaded');
      });
    },
  },
  mounted() {
    this.loadLessons(false);
  },
};
