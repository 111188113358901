import api from 'helpers/Api';
import db from './mobile/db';
import Vue from 'vue';

let promises = {};

/**
 *
 * @param url String
 * @param force Boolean
 * @param storeLocal Boolean If the results should be stored locally or not.
 * @return {Promise<*>}
 */
export default async function (url, force, storeLocal) {
  if (promises[url]) {
    return promises[url];
  }
  const loadRemote = async () => {
    const response = await api.get(url);

    return response.data;
  };

  if (storeLocal === undefined) {
    storeLocal = true;
  }

  if (IS_MOBILE) {
    if (storeLocal) {
      promises[url] = new Promise((resolve) => {
        if (!force) {
          db.getVal(url)
            .then(async (res) => {
              if (res === undefined) {
                const data = await loadRemote();

                await db.setVal(url, data);
                resolve(data);
              } else {
                resolve(res);
              }
            })
            .catch((err) => {
              Vue.$log.error(err);
              alert('db error: ' + JSON.stringify(err));
            });
        } else {
          loadRemote().then(async (data) => {
            await db.setVal(url, data);

            resolve(data);
          });
        }
      });
    } else {
      promises[url] = loadRemote();
    }
  } else {
    promises[url] = loadRemote();
  }

  promises[url].then(() => {
    delete promises[url];
  });

  return promises[url];
}
