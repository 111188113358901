import { Howl } from 'howler';
import { audioPath } from '../helpers/Media';
import _isArray from 'lodash/isArray';
import Vue from 'vue';

let Audio = function (audio, delay, options) {
  this.init(audio, delay, options);
};

Audio.prototype = {
  async init(audio, delay, options) {
    if (!options) options = {};

    this._audio = [];
    this._currentAudio = undefined;
    this._delay = delay;
    this._currentDelay = undefined;
    this._loading = 0;
    this._onload = options.onload ? [{ fn: options.onload }] : [];
    this._onloaderror = options.onloaderror ? [{ fn: options.onloaderror }] : [];
    this._onplay = options.onplay ? [{ fn: options.onplay }] : [];
    this._onend = options.onend ? [{ fn: options.onend }] : [];
    this._onprogress = options.onprogress ? [{ fn: options.onprogress }] : [];

    if (!_isArray(audio)) {
      audio = [audio];
    }

    for (let i = 0; i < audio.length; i++) {
      if (!audio[i] || !audio[i].name) {
        // We don't have an audio file, we can't play it!
        continue;
      }
      this._loading++;
      const path = await audioPath(audio[i].name);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _self = this;

      this._audio.push(
        new Howl({
          src: [path],
          preload: true,
          onload: function () {
            _self._loading--;
            if (_self.isLoaded()) {
              _self._emit('load');
            }
          },
          onloaderror: function () {
            _self._loading--;
            console.error('Unable to load file: "' + path + '"');

            if (_self.isLoaded()) {
              _self._emit('loaderror', audio[i]);
            }
          },
        })
      );
    }
  },
  isLoaded() {
    return this._loading === 0;
  },
  play() {
    return new Promise((resolve) => {
      if (!this.isLoaded()) {
        this.once('load', () => {
          this._play().then(resolve);
        });
      } else {
        this._play().then(resolve);
      }
    });
  },
  playing() {
    return this._currentDelay !== undefined || this._currentAudio !== undefined;
  },
  stop() {
    if (this._currentAudio) {
      this._currentAudio.stop();
      this._currentAudio = undefined;
    }
    if (this._currentDelay) {
      clearTimeout(this._currentDelay);
      this._currentDelay = undefined;
    }

    this._emit('end');
  },
  on(event, fn, once) {
    const events = this['_on' + event];

    events.push({
      fn,
      once: !!once,
    });

    return this;
  },
  once(event, fn) {
    return this.on(event, fn, true);
  },
  off(event, fn) {
    const events = this['_on' + event];

    for (let i = 0; i < events.length; i++) {
      if (fn === events[i].fn) {
        events.splice(i, 1);
        break;
      }
    }

    return this;
  },
  _emit: function (event, msg) {
    const events = this['_on' + event];

    for (let i = events.length - 1; i >= 0; i--) {
      const fn = events[i].fn;
      setTimeout(() => {
        fn.call(this, msg);
      }, 0);

      if (events[i].once === true) {
        events.splice(i, 1);
      }
    }
  },
  _play() {
    const stack = [];

    for (let i = 0; i < this._audio.length; i++) {
      if (i > 0 && this._delay > 0) {
        stack.push(() => this._delayPromise());
      }

      const audio = this._audio[i];
      stack.push(() => this._playPromise(audio, i));
    }

    stack.push(() => this._emit('end'));

    let next = Promise.resolve();

    this._emit('play');
    stack.forEach((promise) => {
      next = next.then(promise);
    });

    return next;
  },
  _playPromise(audio, id) {
    return new Promise((resolve) => {
      audio.once('end', () => {
        this._currentAudio = undefined;
        const progress = Math.round(((id + 1) / this._audio.length) * 100) / 100;
        this._emit('progress', progress);
        resolve();
      });
      this._currentAudio = audio;
      audio.play();
      Vue.$log.debug('Audio.play', id);
    });
  },
  _delayPromise() {
    return new Promise((resolve) => {
      // console.log('audio delay', this._delay);
      this._currentDelay = setTimeout(() => {
        this._currentDelay = undefined;
        resolve();
      }, this._delay);
    });
  },
};

export default Audio;
