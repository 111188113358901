import 'jquery';
import 'popper.js';
import './main.scss';
import 'config/Firebase';

import Vue from 'vue';

import VueTouchEvents from 'vue2-touch-events';
Vue.use(VueTouchEvents, {});

import { BButton, BModal, VBTooltip, VBModal } from 'bootstrap-vue';
Vue.component('BButton', BButton);
Vue.component('BModal', BModal);
Vue.directive('b-tooltip', VBTooltip);
Vue.directive('b-modal', VBModal);

import 'Icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('FaIcon', FontAwesomeIcon);

import 'config/logger';

import Rollbar from 'vue-rollbar';
Vue.use(Rollbar, {
  accessToken: ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: PRODUCTION,
  environment: PRODUCTION ? 'production' : 'dev',
  payload: {
    client: {
      javascript: {
        code_version: VERSION,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
    server: {
      host: window.location.hostname,
    },
  },
});

if (PRODUCTION) {
  Vue.config.errorHandler = (err) => {
    Vue.rollbar.error(err);
  };
}
