<template>
  <div :class="classes">
    <div v-if="!loading" class="row container-fluid">
      <div class="col-12 col-sm-auto pt-2">
        <a
          v-if="enableMenu"
          :class="[$style.hamburger]"
          class="mr-3"
          href="#"
          @click.prevent="toggleMenu"
        >
          <FaIcon icon="bars" />
        </a>
        <slot name="title" />
      </div>
      <div class="col pt-2">
        <slot name="toolbar" />
      </div>
    </div>
    <div
      v-if="!loading"
      id="layout-content"
      ref="content"
      :class="{ rtl: isRtl, [$style.layout_content]: true }"
    >
      <slot />
    </div>
    <Loading v-if="loading" size="lg" center />
  </div>
</template>

<script>
import IsRtl from 'mixins/IsRtl';
import Loading from './components/Loading';

export default {
  components: { Loading },
  mixins: [IsRtl],
  props: {
    enableMenu: {
      type: Boolean,
      default: true,
      required: false,
    },
    fluid: {
      type: Boolean,
      default: true,
    },
    autoHideOnLoad: {
      type: Boolean,
      default: true,
      required: false,
    },
    homeTranslationString: {
      type: String,
      required: false,
      default: 'main_menu',
    },
    homePath: {
      type: Object,
      required: false,
      default() {
        return {
          name: 'index',
        };
      },
    },
  },
  computed: {
    classes() {
      let classes = [this.$style.layout];
      if (this.fluid) {
        classes.push('container-fluid');
      } else {
        classes.push('container');
      }

      return classes;
    },
    loading() {
      return this.autoHideOnLoad && this.$store.getters.appLoading;
    },
  },
  methods: {
    toggleMenu() {
      this.$store.dispatch('toggleMenu');
    },
  },
};
</script>

<style module lang="scss">
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  /*background-color: pink;*/
}
.layout_content {
  display: flex;
  flex-direction: column;
  /*background-color: goldenrod;*/
}
.hamburger {
  font-size: 1.2em;
}
</style>
