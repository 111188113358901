import store from 'store/store';
import {
  LOCALE,
  TRANSLATIONS_LOADING,
  SETTINGS,
  USERNAME_ADMIN,
  LESSON_CATEGORIES,
  CONTENT_PAGES_LIST,
} from 'store/mutationTypes';
import { SetTranslation } from 'i18n';

import { load as loadConfig } from 'data/Config';
import { load as loadContact } from 'data/Contact';
import { load as loadPage } from 'data/ContentPage';

import { downloadAsssets } from '../data/mobile/assets';

export function init(forceReload) {
  if (forceReload === undefined) {
    forceReload = false;
  }
  store.commit(TRANSLATIONS_LOADING, true);
  const lang = store.state.locale;

  let promises = [
    loadConfig(lang, { force: forceReload }).then(async (config) => {
      SetTranslation(lang, config.translations);
      store.commit(SETTINGS, config.settings);
      store.commit(USERNAME_ADMIN, config.username);
      store.commit(LESSON_CATEGORIES, config.lesson_categories);
      store.commit(CONTENT_PAGES_LIST, config.pages);

      if (IS_MOBILE) {
        if (store.getters.setting('enableContact')) {
          loadContact({ force: forceReload }).then((contact) => {
            if (contact.content_page_id) {
              // Download the contact page.
              loadPage(contact.content_page_id, lang, forceReload);
            }
          });
        }

        if (config.pages) {
          for (let i = 0; i < config.pages.length; i++) {
            loadPage(config.pages[i].id, lang);
          }
        }

        // Put last so the other stuff can be loaded in paralell.
        await downloadAsssets(config.lesson_categories);
      }

      // Should be last
      store.commit(TRANSLATIONS_LOADING, false);
      return config;
    }),
  ];

  if (IS_MOBILE) {
    // Via vuex so that the vuex store is updated.
    promises.push(store.dispatch('loadLessons', { force: forceReload }));
    promises.push(store.dispatch('loadLanguages', { force: forceReload }));
  }

  return Promise.all(promises);
}

if (IS_MOBILE) {
  store.subscribe((mutation) => {
    if (mutation.type === LOCALE) {
      // Not ideal as it causes network requests and we should make sure we're online...
      init(false);
    }
  });
}
