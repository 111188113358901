<template>
  <SizedArea ref="sizedArea" v-model="size" :class="$style.imageGrid">
    <div v-if="isMounted" :class="$style.gridContainer">
      <div
        v-for="(p, i) in itemsSorted"
        :key="p.id"
        :class="$style.imageContent"
        :style="cellStyle(i)"
      >
        <div class="image-content-container">
          <div ref="header" class="header-content">
            <slot name="header" :item="p">
              <ContentDisplay v-if="showText && p.content" :content="p.content" />
            </slot>
          </div>
          <ImageDisplay
            v-if="p.display"
            :content="p"
            :play-on-click="playOnClick"
            :enable-play="!isPlaying"
            :show-text="showText"
            :always-selectable="selectable"
            :style="imageStyle(i)"
            :width="cellSizes[i].width"
            :class="$style.imageDisplay"
            @image-click="$emit('image-click', { id: p.id })"
            @play-start="isPlaying = true"
            @play-end="isPlaying = false"
          />
        </div>
      </div>
    </div>
    <div ref="gridFooter" class="image-grid-footer">
      <slot name="gridFooter" />
    </div>
  </SizedArea>
</template>

<script>
import ImageDisplay from './ImageDisplay';
import ContentDisplay from './ContentDisplay';

import IsRtl from '../mixins/IsRtl';
import SizedArea from './SizedArea';

export default {
  components: { SizedArea, ImageDisplay, ContentDisplay },
  mixins: [IsRtl],
  props: {
    items: {
      type: Array,
      required: true,
    },
    playOnClick: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * Number of pixels tall the image should be. Images will not be resized.
     */
    fixedHeight: {
      type: Number,
      default: 0,
      required: false,
    },
    showText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMounted: false,
      size: {
        height: 10,
        width: 10,
        ratio: 1.33,
      },
      isPlaying: false,
      footerHeight: 0,
      headerHeights: [],
    };
  },
  computed: {
    isLandscape() {
      if (this.size.ratio < 1.12) {
        return false;
      }
      return true;
    },
    itemsSorted() {
      if (this.isLandscape) {
        return this.items;
      }

      let sorted = new Array(this.items.length);

      for (let i = 0; i < this.items.length; i++) {
        sorted[this.sortMatrix[i]] = this.items[i];
      }

      return sorted;
    },
    sortMatrix() {
      /**
       * Creates a matrix in the form of [1,3,5,2,4,6] for changing the rows to columns when the
       * images are in portrait mode.
       */
      const qtyItems = this.items.length;
      let matrix = [],
        matrixVal = 0;
      for (let i = 0; i < qtyItems; i++) {
        matrix.push(matrixVal);
        matrixVal = matrixVal + 2;

        if (matrixVal >= qtyItems) {
          matrixVal = 1;
        }
      }
      return matrix;
    },
    gridSize() {
      let qty = this.imageSizes.length,
        rows = 2,
        cols = 3;
      if (qty === 1) {
        rows = 1;
        cols = 1;
      } else if (qty === 2) {
        cols = 2;
        rows = 1;
      } else if (qty <= 4) {
        cols = 2;
      }

      if (!this.isLandscape) {
        if (qty === 2) {
          cols = 1;
          rows = 2;
        } else if (qty === 3) {
          cols = 1;
          rows = 3;
        } else {
          // swap the rows and cols if we're portrait.
          let tmp = rows;
          rows = cols;
          cols = tmp;
        }
      }

      return { rows, cols };
    },
    imageSizes() {
      let items = this.items,
        data = new Array(items.length);
      if (items.length === 0) {
        return data;
      }

      for (let i = 0; i < items.length; i++) {
        const item = items[i].display;
        if (!item) {
          // This item doesn't have a display.
          continue;
        }
        const dataObj = {
          height: item.metadata.height,
          width: item.metadata.width,
          aspectRatio: item.metadata.ratio,
        };
        if (this.isLandscape) {
          data[i] = dataObj;
        } else {
          data[this.sortMatrix[i]] = dataObj;
        }
      }

      return data;
    },
    cellSizes() {
      const qtyImages = this.imageSizes.length,
        rows = this.gridSize.rows,
        cols = this.gridSize.cols;
      let sizes = [];

      for (let index = 0; index < qtyImages; index++) {
        if (!this.imageSizes[index]) {
          // This index didn't have an image, just push an empty object.
          sizes.push({});
          continue;
        }
        const imgItem = this.imageSizes[index];

        let headerHeight = this.headerHeights[index] || 0;

        if (this.fixedHeight > 0) {
          let width = this.fixedHeight * imgItem.aspectRatio;
          if (headerHeight > 0) {
            width = this.size.width / this.gridSize.cols;
          }
          sizes.push({
            height: this.fixedHeight,
            width: Math.round(width),
          });
          continue;
        }

        const colId = index % cols,
          rowId = Math.floor(index / cols),
          firstIndex = rowId * cols,
          lastIndex = firstIndex + cols - 1;
        let totalWidth = 0;

        for (let i = firstIndex; i <= lastIndex; i++) {
          if (this.imageSizes[i] !== undefined) {
            totalWidth += this.imageSizes[i].width;
          }
        }

        let // percent of the row width alloted to this image.
          widthPct = imgItem.width / totalWidth,
          imgWidth = this.size.width * widthPct,
          imgHeight = imgWidth / imgItem.aspectRatio,
          extraWidth = 0,
          headerHeightAll = headerHeight * rows;
        if (widthPct === 1 && cols > 1) {
          // this is the only image on this row and the other rows have more.
          imgWidth = imgWidth * 0.5;
          imgHeight = imgHeight * 0.5;
        }

        if (imgHeight * rows + headerHeightAll + this.footerHeight > this.size.height) {
          let shrinkPct =
            (this.size.height - (headerHeightAll + this.footerHeight)) / (imgHeight * rows);
          imgWidth = imgWidth * shrinkPct;
          imgHeight = imgHeight * shrinkPct;
          extraWidth = this.size.width - this.size.width * shrinkPct;
          // console.log('extra');
        }

        // console.log('cols:', cols, 'rows:', rows, 'qty:', qty, 'landscape:', this.isLandscape, "\n",
        //     'index:', index, 'colId:', colId, 'rowId:', rowId, "\n",
        //     'firstIndex:', firstIndex, 'lastIndex:', lastIndex, "\n",
        //     'totalWidth:', totalWidth, 'widthPct:', widthPct, "\n",
        //     'imgItem.width:', imgItem.width, 'imgItem.height:', imgItem.height, "\n",
        //     'imgWidth:', imgWidth, 'imgHeight:', imgHeight, 'extraWidth:', extraWidth, "\n",
        //     'this.size.height:', this.size.height, 'this.size.width:', this.size.width, "\n",
        //     'imgHeight * rows', (imgHeight * rows)
        // );

        let styles = {
          width: Math.floor(imgWidth),
          height: Math.floor(imgHeight + headerHeight),
        };

        // Margins on either side of the final items in a row to force a break.
        if (colId === 0 && extraWidth > 0) {
          if (this.isRtl) {
            styles['margin-right'] = Math.floor(extraWidth / 2);
          } else {
            styles['margin-left'] = Math.floor(extraWidth / 2);
          }
        }
        // Last column in row or last column in grid.
        if ((colId === cols - 1 || index === qtyImages - 1) && extraWidth > 0) {
          if (this.isRtl) {
            styles['margin-left'] = Math.floor(extraWidth / 2);
          } else {
            styles['margin-right'] = Math.floor(extraWidth / 2);
          }
        }

        sizes.push(styles);
      }

      return sizes;
    },
  },
  watch: {
    async items() {
      // Need to resize when the items change.
      await this.$nextTick();
      this.$refs.sizedArea.resize();
      this.calculateHeaderHeights();
    },
    async isMounted(value) {
      await this.$nextTick();
      this.calculateHeaderHeights();
    },
  },
  mounted() {
    this.isMounted = true;
  },
  updated() {
    // The slot content isn't populated at mount-time.
    this.footerHeight = this.$refs.gridFooter.clientHeight;
  },
  destroyed() {
    this.isMounted = false;
  },
  methods: {
    calculateHeaderHeights() {
      const heights = [];

      if (!this.isMounted || !this.$refs.header || !this.$refs.header.length) {
        this.headerHeights = heights;
      }

      for (let i = 0; i < this.$refs.header.length; i++) {
        heights[i] = this.$refs.header[i].clientHeight;
      }

      this.headerHeights = heights;
    },
    imageStyle(index) {
      if (!this.isMounted || this.imageSizes === null || this.imageSizes[index] === undefined) {
        return {};
      }

      let headerHeight = 0,
        imgItem = this.imageSizes[index];
      if (this.$refs.header !== undefined && this.$refs.header[index] !== undefined) {
        headerHeight = this.$refs.header[index].clientHeight;
      }

      if (this.fixedHeight > 0) {
        let height = this.fixedHeight - headerHeight,
          width = height * imgItem.aspectRatio;
        if (headerHeight > 0 && width > this.size.width / this.gridSize.cols) {
          width = this.size.width / this.gridSize.cols;
          height = width / imgItem.aspectRatio;
        }
        return {
          height: height + 'px',
          width: Math.round(width) + 'px',
        };
      }

      return {};
    },
    cellStyle(index) {
      if (!this.isMounted || this.cellSizes[index] === undefined) {
        return {};
      }

      let styles = {},
        cellSize = this.cellSizes[index];
      for (let key in cellSize) {
        styles[key] = cellSize[key] + 'px';
      }

      return styles;
    },
  },
};
</script>

<style module>
.imageGrid {
  margin-bottom: 5px;
}
.gridContainer {
  text-align: center;

  /* Give some space on the right and left to avoid some layout bugs. */
  margin: 0 -15px;
}
.imageContent {
  /* So they will wrap and center. */
  display: inline-block;
  vertical-align: top;
}
.imageDisplay {
  margin-left: auto;
  margin-right: auto;
}
</style>
