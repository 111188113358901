import { library, config } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretLeft,
  faCaretRight,
  faHome,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
  faCog,
  faTrash,
  faUndo,
  faVideo,
  faSyncAlt,
  faImages,
  faAddressCard,
  faBars,
  faChevronCircleLeft,
  faSave,
  faSignInAlt,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-regular-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

library.add(
  faCaretLeft,
  faCaretRight,
  faHome,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
  faCog,
  faTrash,
  faUndo,
  faVideo,
  faSyncAlt,
  faImages,
  faAddressCard,
  faBars,
  faChevronCircleLeft,
  faSave,
  faSignInAlt,
  faSignOutAlt,
  // Since thes are "regular", the :icon property on FaIcon must be ['far', 'icon-name']!
  faPlayCircle,
  faPauseCircle
);
