var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ClientLayout",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("_lesson_" + _vm.lessonId)) +
                    "/" +
                    _vm._s(_vm.$t("_activity_" + _vm.activityId)) +
                    "\n  "
                )
              ]
            },
            proxy: true
          },
          !_vm.activityLoading
            ? {
                key: "toolbar",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "mb-2" },
                      [
                        !_vm.showResults
                          ? _c("PageNavigation", {
                              staticClass: "float-left",
                              attrs: {
                                "qty-pages": _vm.qtyPages,
                                "allow-forward": _vm.pageId <= _vm.completedPage
                              },
                              model: {
                                value: _vm.pageId,
                                callback: function($$v) {
                                  _vm.pageId = $$v
                                },
                                expression: "pageId"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("ProgressStats", {
                          staticClass: "float-right",
                          attrs: {
                            correct: _vm.correct.length,
                            total: _vm.correct.length + _vm.incorrect.length,
                            score: _vm.currentScore
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      !_vm.activityLoading
        ? [
            !_vm.showResults
              ? _c("ProgressBar", { attrs: { progress: _vm.progressPct } })
              : _vm._e(),
            _vm._v(" "),
            !_vm.showResults
              ? _c(
                  "div",
                  {
                    staticClass: "row justify-content-center",
                    staticStyle: { height: "100%" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "word-bank col-12 col-md-6 col-lg-4" },
                      [
                        _c(
                          "Draggable",
                          {
                            staticClass: "draggable border cornered p-1",
                            attrs: {
                              group: "main",
                              sort: false,
                              move: _vm.textMove,
                              "data-word-bank": "true"
                            },
                            on: { end: _vm.textEnd },
                            model: {
                              value: _vm.wordBank,
                              callback: function($$v) {
                                _vm.wordBank = $$v
                              },
                              expression: "wordBank"
                            }
                          },
                          _vm._l(_vm.wordBank, function(w) {
                            return _c("ContentDisplay", {
                              key: w.id,
                              attrs: {
                                content: w.content,
                                "data-item-id": w.id
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.showResults
                      ? _c(
                          "div",
                          { staticClass: "images col" },
                          [
                            _c("ImageGrid", {
                              attrs: { items: _vm.content },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "feedback-animation",
                                              rawName: "v-feedback-animation",
                                              value: _vm.statuses[item.id],
                                              expression: "statuses[item.id]"
                                            }
                                          ],
                                          staticClass:
                                            "text-drop-container border cornered mt-2"
                                        },
                                        [
                                          _c(
                                            "Draggable",
                                            {
                                              staticClass: "text-drop-area",
                                              attrs: {
                                                group: "main",
                                                sort: false,
                                                move: _vm.textMove,
                                                "data-item-id": item.id
                                              },
                                              on: { end: _vm.textEnd },
                                              model: {
                                                value: _vm.answers[item.id],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.answers,
                                                    item.id,
                                                    $$v
                                                  )
                                                },
                                                expression: "answers[item.id]"
                                              }
                                            },
                                            _vm._l(
                                              _vm.answers[item.id],
                                              function(i, k) {
                                                return _c("ContentDisplay", {
                                                  key: k,
                                                  attrs: {
                                                    content: i.content,
                                                    "data-item-id": i.id
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("InputFeedback", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.statuses[item.id] !==
                                                  null,
                                                expression:
                                                  "statuses[item.id] !== null"
                                              }
                                            ],
                                            attrs: {
                                              visible: true,
                                              success: _vm.statuses[item.id],
                                              small: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }
                                  }
                                ],
                                null,
                                false,
                                1505743280
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showResults
              ? [
                  _c("NextActivity", {
                    attrs: {
                      "lesson-id": _vm.lessonId,
                      "activity-id": _vm.activityId
                    },
                    on: { repeat: _vm.repeat }
                  }),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("Results", {
                    attrs: { correct: _vm.correct, incorrect: _vm.incorrect }
                  })
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("Loading", {
        attrs: { size: "lg", center: "" },
        model: {
          value: _vm.activityLoading,
          callback: function($$v) {
            _vm.activityLoading = $$v
          },
          expression: "activityLoading"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }