import 'firebaseui/dist/firebaseui.css';
import { initializeApp, auth as fbAuth } from 'firebase/app';
import { auth as uiAuth } from 'firebaseui';
import store from 'store/store';
import { AUTH_ENABLED, USERNAME_USER } from '../store/mutationTypes';
import Vue from 'vue';

let ui, initResolve, initReject, fbApp;

const initPromise = new Promise((resolve, reject) => {
  initResolve = resolve;
  initReject = reject;
});

const stop = store.watch(
  (state) => state.data.settings,
  (settings) => {
    // Stop watching because it will never change.
    stop();
    if (!settings.firebaseConfig) {
      initReject();
      console.log('FireBase Auth not configured');
      store.commit(AUTH_ENABLED, false);
      return;
    }
    let config;
    try {
      config = JSON.parse(settings.firebaseConfig);
      if (!config.apiKey) {
        config = JSON.parse(config);
      }
    } catch (e) {
      console.error({ error: e, rawConfig: settings.firebaseConfig, parsedConfig: config });
      initReject();
      store.commit(AUTH_ENABLED, false);
      return;
    }

    fbApp = initializeApp(config);
    const auth = fbApp.auth();
    ui = new uiAuth.AuthUI(auth);

    const unsub = auth.onAuthStateChanged((user) => {
      unsub();
      if (user) {
        // Only resolve once we have a user populated. Otherwise we get issues where this resolves but auth.currentUser
        // isn't yet populated.
        initResolve();
      } else {
        initResolve();
      }
    });
  }
);

/**
 *
 * @return {Promise<boolean|fbApp.Auth>}
 */
export async function getAuth() {
  try {
    await initPromise;
  } catch (e) {
    return false;
  }

  return fbApp.auth();
}

export async function showUI(id, uiShown) {
  try {
    await initPromise;
  } catch (e) {
    return false;
  }

  try {
    ui.start(id, {
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: fbAuth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
        },
        fbAuth.GoogleAuthProvider.PROVIDER_ID,
      ],
      signInSuccessUrl: '/#/',
      callbacks: {
        // signInSuccessWithAuthResult(authResult, redirectUrl) {
        //   console.log(authResult, redirectUrl);
        //   return true;
        // },
        uiShown() {
          if (uiShown) {
            uiShown();
          }
        },
      },
    });
  } catch (e) {
    return false;
  }

  return true;
}

export async function signOut() {
  const auth = await getAuth();
  if (!auth) {
    return;
  }

  await auth.signOut();
}

(async function () {
  const auth = await getAuth();
  if (!auth) {
    return;
  }

  auth.onAuthStateChanged((user) => {
    if (user) {
      store.commit(USERNAME_USER, user.email);
      Vue.rollbar.configure({
        payload: {
          person: {
            id: user.uid,
            email: user.email,
          },
        },
      });
    } else {
      store.commit(USERNAME_USER, undefined);
      Vue.rollbar.configure({
        payload: {
          person: {},
        },
      });
    }
  });
})();
