<template>
  <div
    class="play-controls rounded-circle"
    :class="{
      'drop-shadow': !noDropShadow,
      large: large,
      disabled: !enablePlay && !playing,
      error,
    }"
    @click.stop="play"
  >
    <FaIcon :icon="icon" />
  </div>
</template>

<script>
import Audio from 'lib/Audio';

export default {
  props: {
    audio: {
      // Array if multiple audio files, object if single.
      type: [Array, Object, Audio],
      required: false,
    },
    noDropShadow: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    enablePlay: {
      type: Boolean,
      default: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playing: false,
      error: false,
      currentAudio: undefined,
    };
  },
  computed: {
    icon() {
      if (this.error && this.showError) {
        return 'exclamation-triangle';
      }
      if (this.playing) {
        return ['far', 'pause-circle'];
      }
      return ['far', 'play-circle'];
    },
  },
  watch: {
    audio: {
      handler(newVal) {
        if (this.currentAudio) {
          this.currentAudio
            .off('load', this.loaded)
            .off('play', this.playStarted)
            .off('end', this.playEnded)
            .off('loaderror', this.notFound);
        }

        if (newVal === undefined) {
          return;
        }

        let audio = newVal;

        if (!(audio instanceof Audio)) {
          audio = new Audio(audio, 250, {});
        }

        audio
          .on('load', this.loaded)
          .on('play', this.playStarted)
          .on('end', this.playEnded)
          .on('loaderror', this.notFound);

        this.currentAudio = audio;
      },
      immediate: true,
      // Setting deep: true will cause this to fire on changes WITHIN the Audio object
      // causing a more or less inifinte loop.
    },
  },
  methods: {
    play() {
      if (this.playing) {
        this.currentAudio.stop();
        this.playing = false;
        return;
      }

      if (!this.enablePlay || this.error) {
        // playback is temporarily suspended.
        return;
      }

      return this.currentAudio.play();
    },
    playStarted() {
      this.playing = true;
      this.$emit('play-start');
    },
    playEnded() {
      this.playing = false;
      this.$emit('play-end');
    },
    notFound() {
      this.error = true;
    },
    loaded() {
      this.error = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../styles/variables';

.play-controls {
  height: 48px;
  width: 48px;
  display: inline;
  cursor: pointer;
  font-size: 30px;
  text-align: center;

  transition: color linear 250ms, opacity linear 250ms;
  color: $primary;

  &:hover {
    color: darken($primary, 15%);
  }

  &.drop-shadow {
    background: radial-gradient(
      closest-side,
      rgba(255, 255, 255, 0.7) 0,
      rgba(255, 255, 255, 0.7) 65%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &.large {
    height: 72px;
    width: 72px;
    font-size: 50px;
    margin-top: -10px;
  }

  &.disabled,
  &.disabled:hover {
    color: $secondary;
    cursor: default;
  }

  &.error {
    color: $danger;
    transition: color linear 0ms;
  }
}
</style>
