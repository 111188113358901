var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress-stats" }, [
    _vm._v(
      _vm._s(_vm.correct) +
        "/" +
        _vm._s(_vm.total) +
        ": " +
        _vm._s(_vm.score) +
        "%"
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }