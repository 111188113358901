var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion" },
    _vm._l(_vm.lessons, function(l) {
      return _c(
        "div",
        { key: l.id, staticClass: "card" },
        [
          _c(
            "div",
            { staticClass: "card-header", class: _vm.$style.cardHeader },
            [
              _c(
                "BButton",
                {
                  class: _vm.$style.lessonButton,
                  attrs: { variant: "link" },
                  on: {
                    click: function($event) {
                      _vm.activeLesson = l.id
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("_lesson_" + l.id)) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              l.state === 0 ? _c("em", [_vm._v("(draft)")]) : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BCollapse",
            {
              attrs: {
                id: "lesson-" + l.id,
                visible: _vm.activeLesson === l.id
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-body", class: _vm.$style.cardBody },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    [
                      _vm.credits[l.id]
                        ? _c("li", {
                            staticClass: "list-group-item",
                            class: [
                              _vm.$style.credits,
                              _vm.$style.listGroupItem
                            ],
                            domProps: { innerHTML: _vm._s(_vm.credits[l.id]) }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(l.activities, function(a) {
                        return _c(
                          "li",
                          {
                            key: a.id,
                            staticClass: "list-group-item",
                            class: _vm.$style.listGroupItem
                          },
                          [
                            _c(
                              "RouterLink",
                              {
                                attrs: {
                                  to: {
                                    name: "lesson_" + a.type,
                                    params: { lessonId: l.id, activityId: a.id }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("_activity_" + a.id)) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }