<template>
  <div
    v-if="text.length > 0"
    v-feedback-animation="success"
    :class="classList"
    :style="textStyle"
    @click.stop="click"
  >
    <span v-html="text" />
    <InputFeedback v-if="visible" small :visible="visible" :success="success" />
  </div>
</template>

<script>
import InputFeedback from './InputFeedback';

import FeedbackAnimation from '../directives/FeedbackAnimation';

import Content from '../mixins/Content';

export default {
  components: { InputFeedback },
  directives: { FeedbackAnimation },
  mixins: [Content],
  props: {
    content: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
      required: false,
    },
    success: {
      type: [Boolean, null],
      default: null,
      required: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    textSize: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    text() {
      if (this.content && this.content.text_full) {
        return this.content.text_full.replace(/\n/g, '<br>');
      }
      return '';
    },
    textStyle() {
      let style = this.contentStyle;

      if (this.textSize > 0) {
        style['font-size'] = this.textSize + 'rem';
        style['line-height'] = Math.round(this.textSize * 1.2 * 100) / 100 + 'rem';
      }

      if (!this.transparent) {
        style['background-color'] = 'white';
        style['padding'] = '1px .5rem';
      }

      return style;
    },
    classList() {
      const list = [this.$style.contentDisplay];

      if (this.transparent) {
        list.push(this.$style.transparent);
      } else {
        list.push('border');
        list.push('rounded');
      }
      if (this.isRtl) {
        list.push(this.$style.rtl);
      }
      if (this.selectable) {
        list.push(this.$style.selectable);
      }
      return list;
    },
    visible() {
      return this.success === true || this.success === false;
    },
  },
  methods: {
    click() {
      if (!this.selectable) {
        return;
      }

      this.$emit('text-click');
    },
  },
};
</script>

<style module lang="scss">
@import '../styles/mixins';

.contentDisplay {
  display: inline-block;
  position: relative;
  line-height: 2.3rem;
  background-color: white;
  padding: 0.2rem 0.5rem;

  &.rtl {
    text-align: right;
  }

  &.selectable:not(.transparent) {
    cursor: pointer;
    &:hover {
      @include box-shadow;
    }
  }

  &.transparent {
    background-color: transparent;
  }
}
</style>
