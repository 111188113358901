var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.text.length > 0
    ? _c(
        "div",
        {
          directives: [
            {
              name: "feedback-animation",
              rawName: "v-feedback-animation",
              value: _vm.success,
              expression: "success"
            }
          ],
          class: _vm.classList,
          style: _vm.textStyle,
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.click($event)
            }
          }
        },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } }),
          _vm._v(" "),
          _vm.visible
            ? _c("InputFeedback", {
                attrs: { small: "", visible: _vm.visible, success: _vm.success }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }