var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SizedArea",
    {
      ref: "sizedArea",
      class: _vm.$style.imageGrid,
      model: {
        value: _vm.size,
        callback: function($$v) {
          _vm.size = $$v
        },
        expression: "size"
      }
    },
    [
      _vm.isMounted
        ? _c(
            "div",
            { class: _vm.$style.gridContainer },
            _vm._l(_vm.itemsSorted, function(p, i) {
              return _c(
                "div",
                {
                  key: p.id,
                  class: _vm.$style.imageContent,
                  style: _vm.cellStyle(i)
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-content-container" },
                    [
                      _c(
                        "div",
                        {
                          ref: "header",
                          refInFor: true,
                          staticClass: "header-content"
                        },
                        [
                          _vm._t(
                            "header",
                            [
                              _vm.showText && p.content
                                ? _c("ContentDisplay", {
                                    attrs: { content: p.content }
                                  })
                                : _vm._e()
                            ],
                            { item: p }
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      p.display
                        ? _c("ImageDisplay", {
                            class: _vm.$style.imageDisplay,
                            style: _vm.imageStyle(i),
                            attrs: {
                              content: p,
                              "play-on-click": _vm.playOnClick,
                              "enable-play": !_vm.isPlaying,
                              "show-text": _vm.showText,
                              "always-selectable": _vm.selectable,
                              width: _vm.cellSizes[i].width
                            },
                            on: {
                              "image-click": function($event) {
                                return _vm.$emit("image-click", { id: p.id })
                              },
                              "play-start": function($event) {
                                _vm.isPlaying = true
                              },
                              "play-end": function($event) {
                                _vm.isPlaying = false
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { ref: "gridFooter", staticClass: "image-grid-footer" },
        [_vm._t("gridFooter")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }