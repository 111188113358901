<template>
  <div class="mx-auto" :class="$style.nextActivity">
    <slot />
    <ul>
      <li v-if="nextActivity">
        <RouterLink :to="nextActivityRoute">
          <FaIcon icon="caret-right" :class="$style.icon" />
          <span
            v-html="
              $t('next_activity', {
                activity: $t('_activity_' + nextActivity.id),
              })
            "
          />
        </RouterLink>
      </li>
      <li v-if="!disableRepeat">
        <a href="#" @click.prevent="repeatActivity">
          <FaIcon icon="undo" :class="$style.repeat" />
          <span v-html="$t('repeat_activity', { activity: $t('_activity_' + activityId) })" />
        </a>
      </li>
      <li>
        <RouterLink
          :to="{
            name: 'lesson_category',
            params: { categoryId: lessonCategoryId },
          }"
          variant="link"
        >
          <ImageDisplay
            v-if="category.asset.id"
            :content="{ display: category.asset }"
            :autosize="23"
            no-video
            display-only
            class="d-inline-block"
          />
          {{ $t('_lesson_category_' + lessonCategoryId) }}
        </RouterLink>
      </li>
    </ul>
  </div>
</template>

<script>
import Lessons from '../mixins/Lessons';
import ImageDisplay from './ImageDisplay';

import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

export default {
  components: { ImageDisplay },
  mixins: [Lessons],
  props: {
    lessonId: {
      type: Number,
      required: true,
    },
    activityId: {
      type: Number,
      required: true,
    },
    disableRepeat: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    nextActivity() {
      const currentIndex = _findIndex(
        this.lessonEntity.activities,
        (a) => a.id === this.activityId
      );

      if (this.lessonEntity.activities[currentIndex + 1]) {
        return this.lessonEntity.activities[currentIndex + 1];
      }

      const currentLessonIndex = _findIndex(this.lessonData, (l) => l.id === this.lessonId);
      if (this.lessonData[currentLessonIndex + 1]) {
        return this.lessonData[currentLessonIndex + 1].activities[0];
      }

      return null;
    },
    nextActivityRoute() {
      return {
        name: 'lesson_' + this.nextActivity.type,
        params: {
          lessonId: this.nextActivity.lesson_id,
          activityId: this.nextActivity.id,
        },
      };
    },
    category() {
      return _find(
        this.$store.state.data.lessonCategories,
        (c) => c.id === this.lessonEntity.category_id
      );
    },
  },
  methods: {
    repeatActivity() {
      this.$emit('repeat');
      return false;
    },
  },
};
</script>

<style module lang="scss">
.nextActivity {
  font-size: 1.1rem;

  ul {
    padding-left: 0;
    margin-bottom: 0;
    text-align: center;
  }

  li {
    list-style: none;

    &:not(:first-child) {
      padding-top: 0.2em;
    }
  }
}
.icon {
  font-size: 1.3em;
  margin-bottom: -0.1em;
}

.repeat {
  font-size: 0.8em;
}
</style>
