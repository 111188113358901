var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      !_vm.loading
        ? _c("div", { staticClass: "row container-fluid" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-auto pt-2" },
              [
                _vm.enableMenu
                  ? _c(
                      "a",
                      {
                        staticClass: "mr-3",
                        class: [_vm.$style.hamburger],
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.toggleMenu($event)
                          }
                        }
                      },
                      [_c("FaIcon", { attrs: { icon: "bars" } })],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("title")
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col pt-2" }, [_vm._t("toolbar")], 2)
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "div",
            {
              ref: "content",
              class:
                ((_obj = { rtl: _vm.isRtl }),
                (_obj[_vm.$style.layout_content] = true),
                _obj),
              attrs: { id: "layout-content" }
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("Loading", { attrs: { size: "lg", center: "" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }