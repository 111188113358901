import Vue from 'vue';
import VueLogger from 'vuejs-logger';
import store from 'store/store';

const enabled = store.state.logLevel !== 'disabled';

Vue.use(VueLogger, {
  isEnabled: enabled,
  logLevel: enabled ? store.state.logLevel : 'fatal',
  stringifyArguments: true,
  showConsoleColors: true,
  showMethodName: true,
});
