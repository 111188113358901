<template>
  <ClientLayout :auto-hide-on-load="false">
    <template #title>
      <h1>{{ $t('welcome_to', { learn_lang_name: $t('learn_lang_name') }) }}</h1>
    </template>

    <Loading v-if="loading" size="lg" />
    <div id="login-container" ref="loginContainer"></div>
  </ClientLayout>
</template>

<script>
import ClientLayout from '../ClientLayout';
import { showUI } from '../config/Firebase';
import Loading from '../components/Loading';

export default {
  components: { Loading, ClientLayout },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await showUI(this.$refs.loginContainer, () => {
        this.loading = false;
      });
    },
  },
};
</script>
