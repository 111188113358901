import Base from './BaseData';
import { isOffline, load as loadFromDb, downloadActivity } from './mobile/activity';

export async function load(opt) {
  if (IS_MOBILE) {
    const availableOffline = await isOffline(opt.id);

    if (availableOffline && !opt.force) {
      const activity = await loadFromDb(opt.id);
      if (activity) {
        return activity;
      }
    }

    return await downloadActivity(opt.id);
  } else {
    return Base(generateUrl(opt.id), opt.force, false);
  }
}

export function generateUrl(id) {
  return `activity/${id}`;
}
