<template>
  <Layout :fluid="false">
    <div v-if="!langLoading" class="container">
      <div class="row">
        <div class="col">
          <h1>{{ $t('settings.title') }}</h1>
        </div>
      </div>
      <BForm inline>
        <BFormCheckbox v-model="enableFeedbackSounds">
          {{ $t('settings.enable_feedback_sounds') }}
        </BFormCheckbox>

        <select v-if="langData.length > 1" v-model="locale" class="form-control">
          <option v-for="l in langData" :key="l" :value="l">
            {{ $t('_language_' + l) }}
          </option>
        </select>
      </BForm>
      <BButton variant="primary" :to="{ name: 'index' }">
        <FaIcon icon="save" /> {{ $t('save') }}
      </BButton>
    </div>
    <Loading v-if="langLoading" size="lg" center />
  </Layout>
</template>

<script>
import Layout from '../ClientLayout';
import Languages from 'mixins/Languages';
import { ENABLE_FEEDBACK_SOUNDS, LOCALE } from '../store/mutationTypes';

import { BForm, BFormCheckbox } from 'bootstrap-vue';
import Loading from '../components/Loading';

export default {
  components: { Loading, Layout, BForm, BFormCheckbox },
  mixins: [Languages],
  computed: {
    enableFeedbackSounds: {
      get() {
        return this.$store.state.enableFeedbackSounds;
      },
      set(value) {
        this.$store.commit(ENABLE_FEEDBACK_SOUNDS, value);
      },
    },
    locale: {
      get() {
        return this.$store.state.locale;
      },
      set(value) {
        this.$store.commit(LOCALE, value);
      },
    },
  },
};
</script>
