import _delay from 'lodash/delay';

export default function (ms) {
  return new Promise((resolve) => {
    // console.log('delay', ms);
    _delay(() => {
      resolve(true);
    }, ms);
  });
}
