var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.qtyPages > 1
    ? _c(
        "div",
        { staticClass: "page-navigation" },
        [
          _vm.qtyPages > 1 && !_vm.disableNavigation
            ? _c(
                "BButton",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      value: _vm.navLeftText,
                      expression: "navLeftText",
                      modifiers: { hover: true }
                    }
                  ],
                  attrs: {
                    id: "nav-left",
                    disabled: _vm.isNavRtl
                      ? _vm.isLastPage || !_vm.allowForward
                      : _vm.isFirstPage
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickLeft($event)
                    }
                  }
                },
                [_c("FaIcon", { attrs: { icon: "caret-left" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("page_x_of_y", [_vm.currentPage, _vm.qtyPages]))
            )
          ]),
          _vm._v(" "),
          _vm.qtyPages > 1 && !_vm.disableNavigation
            ? _c(
                "BButton",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      value: _vm.navRightText,
                      expression: "navRightText",
                      modifiers: { hover: true }
                    }
                  ],
                  attrs: {
                    id: "nav-right",
                    disabled: _vm.isNavRtl
                      ? _vm.isFirstPage
                      : _vm.isLastPage || !_vm.allowForward
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickRight($event)
                    }
                  }
                },
                [_c("FaIcon", { attrs: { icon: "caret-right" } })],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }