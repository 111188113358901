<template>
  <div v-if="value" :class="classes" class="text-center">
    <FaIcon icon="spinner" spin />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
    center: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      let classes = [this.$style.loading];

      if (this.size === 'lg') {
        classes.push(this.$style.large);
      } else if (this.size === 'xs') {
        classes.push(this.$style.xsmall);
      }

      if (this.inline) {
        classes.push(this.$style.inline);
      }

      if (this.center) {
        classes.push(this.$style.center);
      }
      return classes;
    },
  },
};
</script>

<style module lang="scss">
.loading {
  font-size: 2em;
  width: 100%;

  &.large {
    font-size: 4em;
  }
  &.xsmall {
    font-size: 1em;
  }
  &.inline {
    width: auto;
    margin-right: 3px;
    display: inline-block;
  }
  &.center {
    margin-top: 20%;
  }
}
</style>
