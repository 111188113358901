<template>
  <div class="progress-stats">{{ correct }}/{{ total }}: {{ score }}%</div>
</template>

<script>
export default {
  props: {
    correct: {
      type: Number,
      default: 0,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
      required: true,
    },
    score: {
      type: Number,
      default: 0,
      required: true,
    },
  },
};
</script>

<style scoped>
.progress-stats {
  white-space: nowrap;
}
</style>
